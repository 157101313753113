import React, { Component } from 'react';
import { Alert, Card, CardHeader, CardFooter, CardBody, Form, FormGroup, Label, Input, Button, Container, Row, Col } from 'reactstrap';
import cx from 'classnames';

import { observer, inject } from 'mobx-react';

import { FormattedMessage } from 'react-intl';
import { IoIosChatboxes } from 'react-icons/io';
import { FaUserTimes, FaPaperPlane } from 'react-icons/fa';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { DateTimePicker, CardMask } from 'components/common';
import { shortDateCellRenderer, yzoneSMSResultCellRenderer, phonePattern, localeTextFunc } from 'libs/Utils';

import './SmsArea.scss';

@inject('smsSend')
@observer
class SmsArea extends Component {
  phoneBookGridApi = null;

  handlePhoneBookGridReady = (params) => {
    this.phoneBookGridApi = params.api;
  };

  handleShowClick = () => {
    this.props.smsSend.toggleShow();
  };

  handleSendClick = () => {
    const list = this.props.smsSend.phoneList;
    const compCode = list.length > 0 ? list[0].compCode : '';

    this.phoneBookGridApi.stopEditing(false);
    this.props.smsSend.setRetest(false);
    this.props.smsSend.setMessage(
      `[안내]#병원이름# 검진 결과

#환자이름# 님, 안녕하세요.
#병원이름# 에서 검진하신 검사결과 안내드립니다.
하단 링크 접속하여 모바일로 확인 가능합니다.

본 모바일 검진결과 서비스는
#병원이름#에서 수검자의 편의를 위해 제공해 드리는 서비스 입니다.
문의사항은 #병원이름# #병원전화번호#로 연락주시기 바랍니다.
감사합니다.

https://yz.ntllab.com/?fcode=` + `#검사번호#`
    );
    this.props.smsSend.send(true);
  };

  render() {
    const { smsSend } = this.props;

    return (
      <div className={cx('SmsArea-yzone', smsSend.show ? '' : 'hide')}>
        <div className="tip" onClick={this.handleShowClick}>
          <IoIosChatboxes />
          <span className="badge badge-pill badge-light">{smsSend.phoneCount}</span>
        </div>
        <Container fluid className="h-flex">
          <Alert className="mr-2 ml-2 mb-0" color="warning">
            <div>
              <FormattedMessage id="comp.res.sms.contents1" />
            </div>
            <div>
              <FormattedMessage id="comp.res.sms.contents3" />
            </div>
          </Alert>
          <Row style={{ flex: 1 }} className="flex-column-reverse flex-lg-row">
            <Col>
              <div className="container-fluid h-flex pl-0">
                <PhoneBookGridCard onGridReady={this.handlePhoneBookGridReady} />
                <SendPane onSendClick={this.handleSendClick} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

@inject('locale')
@inject('smsSend')
@observer
class SendPane extends Component {
  messageGridApi = null;

  handleSendClick = () => {
    this.props.onSendClick();
  };

  render() {
    const { locale, smsSend } = this.props;
    return (
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <Form>
                <div className="form-row justify-content-center">
                  <FormGroup>
                    <Label>
                      <Input
                        type="checkbox"
                        id="reServeSend"
                        checked={smsSend.reserve}
                        onChange={(e) => {
                          smsSend.setReserve(e.target.checked);
                        }}
                      />
                      <FormattedMessage id="field.sms.receiverList.scheduledSms" />
                    </Label>
                    <DateTimePicker value={smsSend.reserveDatetime} onChange={(e) => smsSend.setReserveDatetime(e.target.value)} dropUp showTime />
                  </FormGroup>
                </div>
                <div className="form-row">
                  <FormGroup></FormGroup>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Button size="lg" color="primary" block onClick={this.handleSendClick}>
            <FaPaperPlane /> <FormattedMessage id="button.sms.send" />
          </Button>
        </Col>
      </Row>
    );
  }
}

const defaultColDef = {
  sortable: true,
  singleClickEdit: true
};

@inject('locale')
@inject('smsSend')
@observer
class PhoneBookGridCard extends Component {
  state = {
    selectedCount: 0
  };

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
  };

  handleRemoveClick = () => {
    this.props.smsSend.removePhoneList(this.gridApi.getSelectedRows());
  };

  handleCellEditingStarted = (e) => {
    //아 모르겠다.
    const input = document.querySelector('.ag-cell-edit-input');
    if (input.value === '') input.value = '010';
  };

  handleSelectionChanged = () => {
    this.setState({
      selectedCount: this.gridApi.getSelectedRows().length
    });
  };

  render() {
    const { locale } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader> {locale.fm('comp.sms.title.receiverList')} </CardHeader>
        <CardBody className="np">
          <div className="DataGrid">
            <div className="DataGrid ag-theme-balham" style={{ flex: 1, overflow: 'hidden' }}>
              <AgGridReact
                reactNext={true}
                localeTextFunc={localeTextFunc}
                suppressRowClickSelection={true}
                suppressNoRowsOverlay={true}
                deltaRowDataMode={true}
                columnDefs={[
                  { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
                  { headerName: locale.fm('field.sms.receiverList.requestDate'), field: 'receivedDate', width: 90, cellRenderer: shortDateCellRenderer },
                  { headerName: locale.fm('field.patname'), field: 'patName', width: 110 },
                  {
                    headerName: locale.fm('field.sms.receiverList.mobile'),
                    field: 'phone',
                    width: 110,
                    editable: true,
                    cellClassRules: {
                      invalid: ({ value }) => !phonePattern.test(value)
                    }
                  },
                  { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 110 },
                  {
                    headerName: locale.fm('field.txtresult'),
                    field: 'txtResult',
                    width: 70,
                    cellRenderer: yzoneSMSResultCellRenderer,
                    cellClassRules: {
                      'text-black-50': ({ data }) => (data.reportId && !data.completed) || data.statusCode < 6
                    }
                  },
                  { headerName: locale.fm('field.fcode'), field: 'idx', width: 80 }
                ]}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                getRowNodeId={(data) => data.idx}
                animateRows={true}
                enterMovesDownAfterEdit={true}
                onGridReady={this.handleGridReady}
                onSelectionChanged={this.handleSelectionChanged}
                rowData={this.props.smsSend.phoneList}
                onCellEditingStarted={this.handleCellEditingStarted}
              />
            </div>
            <div className="area-paging">
              <div>
                <span>
                  <FormattedMessage id="field.total" /> : {this.props.smsSend.phoneCount}
                </span>{' '}
                /{' '}
                <span>
                  <FormattedMessage id="field.selected" /> :{' '}
                  {this.state.selectedCount === 0 ? 0 : <span className="badge badge-primary">{this.state.selectedCount.toLocaleString()}</span>}
                </span>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter className="card-toolbar text-center">
          <Button size="sm" color="warning" onClick={this.handleRemoveClick}>
            <FaUserTimes /> {locale.fm('button.sms.delReceiver')}
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

export default SmsArea;
