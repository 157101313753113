import LocaleStore from './LocaleStore';
import ScreenStore from './ScreenStore';
import ZoomWindowStore from './ZoomWindowStore';
import PictureListStore from './PictureListStore';
import AuthStore from './AuthStore';
import DoctorEditWindowStore from './DoctorEditWindowStore';
import CervicoRequestStore from './CervicoRequestStore';
import CervicoResultStore from './CervicoResultStore';
import CervicoResultEditWindowStore from './CervicoResultEditWindowStore';
import CervicoResultDeleteWindowStore from './CervicoResultDeleteWindowStore';
import CervicoResultSmsWindowStore from './CervicoResultSmsWindowStore';
import SmsSendStore from './SmsSendStore';
import OtherSmsSendStore from './OtherSmsSendStore';
import ReportStore from './ReportStore';
import SmsTemplateStore from './SmsTemplateStore';
import SmsResultStore from './SmsResultStore';
import OtherTestStore from './OtherTestStore';
import OtherTestResultSmsWindowStore from './OtherTestResultSmsWindowStore';
import CervicoResultWindowStore from './CervicoResultWindowStore';
import CervicoResultCompareWindowStore from './CervicoResultCompareWindowStore';
import SettingStore from './SettingStore';
import PdfWindowStore from './PdfWindowStore';
import CerviBoxStore from './CerviBoxStore';
import CerviBoxAddStore from './CerviBoxAddStore';
import CerviBoxEditStore from './CerviBoxEditStore';
import CerviAiStore from './CerviAiStore';
import CerviBoxCopyStore from './CerviBoxCopyStore';
import CerviBoxAddForResultStore from './CerviBoxAddForResultStore';
import CerviBoxAddForAIAnalysisStore from './CerviBoxAddForAIAnalysisStore';
import CervitemStore from './CervitemStore';
import CervitemCartStore from './CervitemCartStore';
import CervitemProductStore from './CervitemProductStore';
import CervitemOrderStore from './CervitemOrderStore';
import CervitemAddrStore from './CervitemAddrStore';
import ProductWindowStore from './ProductWindowStore';
import CheckWindowWidthStore from './CheckWindowWidthStore';
import CerviAiResultWindowStore from './CerviAiResultWindowStore';
import CerviAiResultWindowWithoutPatInfoStore from './CerviAiResultWindowWithoutPatInfoStore';
import CerviAiDeleteWindowStore from './CerviAiDeleteWindowStore';
import CerviAiResultEditWindowStore from './CerviAiResultEditWindowStore';
import CerviAiCsGuideStore from './CerviAiCsGuideStore';
import YZonePkgStore from './YZonePkgStore';
import AndroidStore from './AndroidStore';

import { configure } from 'mobx';

configure({ enforceActions: 'observed' });
class RootStore {
  constructor() {
    this.locale = new LocaleStore(this);
    this.screen = new ScreenStore(this);
    this.zoomWindow = new ZoomWindowStore(this);
    this.pdfWindow = new PdfWindowStore(this);
    this.auth = new AuthStore(this);
    this.pictureList = new PictureListStore(this);
    this.cervicoRequest = new CervicoRequestStore(this);
    this.cervicoResult = new CervicoResultStore(this);
    this.cervicoResultEditWindow = new CervicoResultEditWindowStore(this);
    this.patientAIInfoEditWindow = new CerviAiResultEditWindowStore(this);
    this.cervicoResultDeleteWindow = new CervicoResultDeleteWindowStore(this);
    this.cervicoResultSmsWindow = new CervicoResultSmsWindowStore(this);
    this.smsSend = new SmsSendStore(this);
    this.otherSmsSend = new OtherSmsSendStore(this);
    this.otherTestResultSmsWindow = new OtherTestResultSmsWindowStore(this);
    this.smsTemplate = new SmsTemplateStore(this);
    this.smsResult = new SmsResultStore(this);
    this.otherTest = new OtherTestStore(this);
    this.report = new ReportStore(this);
    this.cervicoResultWindow = new CervicoResultWindowStore(this);
    this.cervicoResultCompareWindow = new CervicoResultCompareWindowStore(this);
    this.setting = new SettingStore(this);
    this.doctorEditWindow = new DoctorEditWindowStore(this);
    this.cerviBox = new CerviBoxStore(this);
    this.cerviBoxForSearch = new CerviBoxStore(this);
    this.cerviBoxAdd = new CerviBoxAddStore(this);
    this.cerviBoxEdit = new CerviBoxEditStore(this);
    this.cerviAi = new CerviAiStore(this);
    this.cerviBoxCopy = new CerviBoxCopyStore(this);
    this.cerviBoxAddForResult = new CerviBoxAddForResultStore(this);
    this.cerviBoxAddForAIAnalysis = new CerviBoxAddForAIAnalysisStore(this);
    this.cervitem = new CervitemStore(this);
    this.cervitemCart = new CervitemCartStore(this);
    this.cervitemProduct = new CervitemProductStore(this);
    this.cervitemOrder = new CervitemOrderStore(this);
    this.cervitemAddr = new CervitemAddrStore(this);
    this.productWindow = new ProductWindowStore(this);
    this.checkWindowWidth = new CheckWindowWidthStore(this);
    this.cerviAiResultWindow = new CerviAiResultWindowStore(this);
    this.cerviAiResultWindowWithoutPatInfo = new CerviAiResultWindowWithoutPatInfoStore(this);
    this.cerviAiDeleteWindow = new CerviAiDeleteWindowStore(this);
    this.cerviAiCsGuide = new CerviAiCsGuideStore(this);
    this.yzonePkg = new YZonePkgStore(this);
    this.android = new AndroidStore(this);

    //치사하지만 locale store 는 global scope 에서 참조
    //Utils 와 ComfirmModal 에서 사용
    window._locale = this.locale;
  }
}

export default RootStore;
