import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Form, FormGroup, Input, Label } from 'reactstrap';
import { FaSearch, FaTimes, FaTrash } from 'react-icons/fa';
import { IoIosChatboxes } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { CardMask, DataGrid, DateTimePicker } from '../common';
import { dateCellRenderer, shortDateCellRenderer, socialNumRenderer, yzoneResultCellRenderer, yzoneCervicoResultCellRenderer, smsReqTypeMap } from '../../libs/Utils';
import moment from 'moment/moment';

import SmsArea from './SmsArea';
import CervicoResultSmsWindow from '../cervico/result/CervicoResultSmsWindow';

@inject('yzonePkg')
@inject('smsSend')
@observer
class YZonePkg extends Component {
  gridApi = null;

  handleGridReady = (params) => {
    this.gridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.gridApi.ensureIndexVisible(0, 'top');
  };

  componentDidMount() {
    this.props.smsSend.init();
    this.props.smsSend.setShow(false);
  }

  componentWillUnmount() {
    this.props.yzonePkg.init();
    this.props.smsSend.init();
    this.props.smsSend.setShow(false);
  }

  render() {
    return (
      <>
        <div className="container-fluid h-flex">
          <SearchFormCard onAfterSearch={this.handleResetGridScrollbar} />
          <ResultGridCard onGridReady={this.handleGridReady} onAfterMove={this.handleResetGridScrollbar} />
          <div className="mb-0" />
          <SmsArea />
        </div>
        <CervicoResultSmsWindow />
      </>
    );
  }
}

@inject('locale')
@inject('yzonePkg')
@inject('smsSend')
@inject('cervicoResultSmsWindow')
@observer
class ResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.yzonePkg.loadDataGrid();
  };

  handleMovePage = async (page) => {
    await this.props.yzonePkg.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleReportBtnClick = (idx) => {
    this.props.yzonePkg.print(idx);
  };

  handleSmsClick = () => {
    if (this.selected.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    }

    const list = this.selected.map((d) => Object.assign({ phone: '' }, d));

    this.props.smsSend.setShow(true);
    this.props.smsSend.addPhoneList(list);
  };

  handleSmsResultClick = (idx, smsType) => {
    this.props.cervicoResultSmsWindow.setRequestInfo(idx, smsType);
  };

  render() {
    const { locale } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader>
          <Button size="sm" onClick={this.handleSmsClick}>
            <IoIosChatboxes /> <FormattedMessage id="button.res.sms" />
          </Button>
        </CardHeader>
        <CardBody className="np h-flex">
          <DataGrid
            columnDefs={[
              { width: 30, checkboxSelection: true, headerCheckboxSelection: true },
              { headerName: locale.fm('field.fcode'), field: 'idx', width: 80 },
              { headerName: locale.fm('field.reqdate'), field: 'regDate', width: 80, cellRenderer: shortDateCellRenderer },
              { headerName: locale.fm('field.patname'), field: 'patName', width: 80 },
              { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 100 },
              { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 100, cellRenderer: socialNumRenderer },
              { headerName: locale.fm('field.yzone.result'), field: 'txtResultList', width: 80, cellRenderer: yzoneCervicoResultCellRenderer },
              { headerName: locale.fm('field.yzone.test'), field: 'testName', width: 80 },
              { headerName: locale.fm('field.yzone.lbc'), field: 'txtLibraryCode', width: 100, cellRenderer: yzoneResultCellRenderer },
              { headerName: locale.fm('field.yzone.hpv'), field: 'hpvResult', width: 60, cellRenderer: yzoneResultCellRenderer },
              { headerName: locale.fm('field.yzone.std'), field: 'stdResult', width: 60, cellRenderer: yzoneResultCellRenderer },
              { headerName: locale.fm('button.report'), width: 70, cellRenderer: 'reportClickRenderer', cellClass: 'button' },
              {
                headerName: locale.fm('field.rsltdate'),
                field: 'smsYzoneDate',
                valueGetter: ({ data }) => {
                  return { data, code: smsReqTypeMap.YZONE_검사결과 };
                },
                cellRenderer: 'smsClickRenderer',
                width: 140,
                cellClass: 'button'
              }
            ]}
            rowSelection="multiple"
            isRowSelectable={({ data }) => data.yzStatus === 'F'}
            onGridReady={this.handleGridReady}
            onSelectionChanged={this.handleGridSelectionChanged}
            onMovePage={this.handleMovePage}
            dataGrid={this.props.yzonePkg.dataGrid}
            enableBrowserTooltips={true}
            context={{
              componentParent: this
            }}
            frameworkComponents={{
              reportClickRenderer: ReportClickRenderer,
              smsClickRenderer: SmsClickRenderer
            }}
          />
        </CardBody>
        <CardMask show={this.props.yzonePkg.mask} />
      </Card>
    );
  }
}

const SmsClickRenderer = ({ value: { data, code }, context }) => {
  const invokeParentMethod = () => {
    context.componentParent.handleSmsResultClick(data.idx, code);
  };

  const isValueEmpty = code == null || data[code.field] == null;

  return !isValueEmpty ? (
    // dateCellRenderer로 하면 동일한 날짜가 출력됨, moment로 직접함
    <button onClick={invokeParentMethod} className={`btn ${isValueEmpty ? 'btn-outline-secondary' : 'btn-outline-primary'}`}>
      {moment(data[code.field]).format('YYYY-MM-DD HH:mm')}
    </button>
  ) : (
    <div />
  );
};

@inject('locale')
@inject('yzonePkg')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.yzonePkg.changeSearch(i.name, i.value);
  };

  handleResetClick = async (e) => {
    await this.props.yzonePkg.triggerReset();
    this.props.onAfterSearch();
  };

  handleSearchClick = async (e) => {
    await this.props.yzonePkg.triggerSearch();
    this.props.onAfterSearch();
  };

  handleKeyUpEnter = (e) => {
    if (e.key === 'Enter') this.handleSearchClick();
  };

  handleTodayClick = () => {
    this.props.yzonePkg.changeSearch('startDate', moment().startOf('day').toDate());
    this.props.yzonePkg.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1wClick = () => {
    this.props.yzonePkg.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.yzonePkg.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  render() {
    const {
      locale,
      yzonePkg: { search: s }
    } = this.props;
    const hc = this.handleSearchChange;

    return (
      <Card>
        <CardHeader>
          {' '}
          <FormattedMessage id="comp.yzone.title.searchcondition" />{' '}
        </CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>
                  {' '}
                  <FormattedMessage id="field.sms.receiverList.requestDate" />{' '}
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleTodayClick} outline>
                    <FormattedMessage id="button.today" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="sm" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="sm" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
            </div>
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

class ReportClickRenderer extends Component {
  invokeParentMethod = (d) => {
    this.props.context.componentParent.handleReportBtnClick(this.props.data.idx);
  };

  render() {
    return this.props.data.yzStatus === 'F' ? (
      <button onClick={this.invokeParentMethod} className="btn btn-outline-primary">
        <FormattedMessage id="button.report" />
      </button>
    ) : (
      ''
    );
  }
}

export default YZonePkg;
