import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Alert, Button } from 'reactstrap';
import PictureListCard from './PictureListCard';
import PatForm from './PatForm';
import RequestConfirm from './RequestConfirm';

import './CervicoRequest.scss';
import { CardMask } from 'components/common';
import { FormattedMessage } from 'react-intl';
import { FaFileUpload, FaSave, FaBox, FaUserAstronaut, FaConnectdevelop } from 'react-icons/fa';
import { CerviBoxAddWindow } from 'components/cervibox';
import { toast } from 'react-toastify';

@inject('locale')
@inject('cervicoRequest')
@inject('auth')
@inject('pictureList')
@inject('cerviBoxAdd')
@inject('cerviBox')
@observer
class CervicoRequest extends Component {
  state = {
    reqAIAnaly: false,
    reqDetail: false
  };
  handleRequestClick = (e) => {
    this.setState({
      reqAIAnaly: false,
      reqDetail: false
    });
    e.preventDefault();
    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.request(true, false, false, true, true);
    } else {
      this.props.cervicoRequest.request(true, false, false, true);
    }
  };

  handleDeferRequestClick = (e) => {
    e.preventDefault();
    this.props.cervicoRequest.request(false);
  };

  handleRequestCerviAiClick = (e) => {
    this.setState({
      reqAIAnaly: true,
      reqDetail: false
    });
    // Todo: ai분석 클릭시 실행
    e.preventDefault();
    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.requestCerviAi(true, true);
    } else {
      this.props.cervicoRequest.requestCerviAi(true);
    }
  };

  handleDetailRequestClick = (e) => {
    this.setState({
      reqAIAnaly: false,
      reqDetail: true
    });

    e.preventDefault();
    const reqConfirmYN = this.props.auth.doctor.reqConfirmYN;
    if (reqConfirmYN) {
      this.props.cervicoRequest.request(true, false, false, true, true, true);
    } else {
      this.props.cervicoRequest.request(true, false, false, true, false, true);
    }
  };

  handleCerviboxAddClick = () => {
    const list = this.props.pictureList.group.reduce((a, c) => [...a, ...c.list], []).filter((e) => e.checked);
    if (list.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
      return;
    }
    this.props.cerviBoxAdd.show(list);
  };

  render() {
    const { cervicoRequest, auth } = this.props;
    return (
      <div className="CervicoRequest">
        <div className="container-fluid h-flex">
          <PictureListCard />
        </div>
        <div className="container-fluid h-flex right">
          <PatForm useHistoryYn useDetailHistoryYn useAIOnPatType={auth.doctor.useAIOnPatType} />
          <div className="row btn-container">
            {auth.doctor.useRequest && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              <div className="pt-1">
                <Button className="col-sm" color="primary" size="lg" onClick={this.handleRequestClick}>
                  <FaFileUpload /> <FormattedMessage id="button.request" />
                </Button>
              </div>
            ) : null}
            {auth.doctor.useDeferRequest && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              <div className="pt-1">
                <Button className="col-sm" color="secondary" size="lg" onClick={this.handleDeferRequestClick}>
                  <FaSave /> <FormattedMessage id="button.deferRequest" />
                </Button>
              </div>
            ) : null}
            {auth.doctor.useCerviboxYN && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              this.props.locale._locale === 'ko' ? (
                <div className="pt-1">
                  <Button className="col-sm" color="purple" size="lg" onClick={this.handleCerviboxAddClick}>
                    <FaBox /> <FormattedMessage id="button.cervibox" />
                  </Button>
                </div>
              ) : null
            ) : null}
            {auth.doctor.useAI && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              <div className="pt-1">
                <Button className="col-sm" color="sunsetOrange" style={{ color: 'white' }} size="lg" onClick={this.handleRequestCerviAiClick}>
                  <FaUserAstronaut /> <FormattedMessage id="button.ai.analy" />
                </Button>
              </div>
            ) : null}
            {auth.doctor.useAI && auth.doctor.useDetailRequest && process.env.REACT_APP_SERVER_TYPE !== 'vm' ? (
              <div className="pt-1">
                <Button className="col-sm" color="success" size="lg" onClick={this.handleDetailRequestClick}>
                  <FaConnectdevelop /> <FormattedMessage id="button.detailRequest" />
                </Button>
              </div>
            ) : null}
          </div>
          <div>
            {cervicoRequest.patinfo.refTestCase !== null ? (
              <Alert color="warning">
                <div className="defer-title">
                  <FormattedMessage id="comp.req.defer.title" />
                </div>
                <FormattedMessage id="comp.req.defer.contents" />
              </Alert>
            ) : (
              ''
            )}
          </div>
        </div>
        <CardMask show={cervicoRequest.mask} />
        <CerviBoxAddWindow />
        <RequestConfirm reqAIAnaly={this.state.reqAIAnaly} reqDetail={this.state.reqDetail} />
      </div>
    );
  }
}

export default CervicoRequest;
