import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import AiListCardForAndroid from './AiListCardForAndroid';
import CerviAiResultWindowForAndroid from '../../CerviAiResultWindowForAndroid';
import { CardMask } from 'components/common';
import './CerviAiAndroid.scss';

@inject('android')
@observer
class CerviAiAndroid extends Component {
  render() {
    const { android } = this.props;
    return (
      <div className="CerviAiAndroid">
        <div className="h-flex">
          <AiListCardForAndroid />
        </div>
        <CardMask show={android.mask} />
        <CerviAiResultWindowForAndroid />
      </div>
    );
  }
}

export default CerviAiAndroid;
