import { observable, action, runInAction, toJS } from 'mobx';
import moment from 'moment';
import axios from 'axios';
import { ajaxErrorHandler, searchSerialize } from 'libs/Utils';
import { toast } from 'react-toastify';

const defaultSearch = {
  reportType: 'Cervico',
  patName: '',
  chartNum: '',
  socialNum: '',
  mobile: '',
  startDate: null,
  endDate: null,
  result: ''
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

export default class OtherTestStore {
  @observable search = { ...defaultSearch };
  @observable dataGrid = { ...defaultDataGrid };
  @observable mask = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  @action
  init = () => {
    this.initSearch();
    this.dataGrid = { ...defaultDataGrid };
    this.mask = false;
  };

  @action
  initSearch = () => {
    const reportType = localStorage.getItem('otherTestReportType');
    this.search = Object.assign(
      { ...defaultSearch },
      {
        reportType: reportType,
        startDate: moment().startOf('day').add(-1, 'month').toDate(),
        endDate: moment().startOf('day').toDate()
      }
    );
  };

  @action
  triggerSearch = async () => {
    await this.loadDataGrid();
  };

  @action
  triggerReset = async () => {
    this.initSearch();
    await this.loadDataGrid();
  };

  @action
  triggerRefresh = async () => {
    await this.loadDataGrid(this.dataGrid.page);
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
    if (key === 'reportType') {
      this.triggerSearch();
    }
  };

  @action
  loadDataGrid = async (page = 1) => {
    const startDate = moment(this.search.startDate);
    const endDate = moment(this.search.endDate);

    // if (endDate.diff(startDate, 'days') > 92) {
    //   toast.warn(this.locale.fm('store.otherTest.3month'));
    //   return;
    // }

    this.mask = true;
    this.normalizeSearch();
    localStorage.setItem('otherTestReportType', this.search.reportType);
    try {
      let url = '';
      let s = null;

      if (this.search.reportType === 'Cervico') {
        url = '/api/rq/cervico/worklist';
        s = Object.assign({ ...this.root.cervicoResult.search }, this.search);
        s.scope = 'all';
      } else {
        url = '/api/rq/other-test';
        s = this.search;
      }

      const response = await axios.get(url, {
        params: searchSerialize(s, { page: page })
      });
      runInAction('OtherTestStore : async loadDataGridload -> success', () => {
        this.dataGrid = response.data;
        this.mask = false;
      });
    } catch (error) {
      runInAction('OtherTestStore : async loadDataGridload -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };

  @action
  normalizeSearch = () => {
    //param 검증
    const s = this.search;
    if (s.reportType === null) s.reportType = 'Cervico';
    if (!moment(s.startDate).isValid()) s.startDate = null;
    if (!moment(s.endDate).isValid()) s.endDate = null;
    if (s.startDate !== null && s.endDate === null) s.endDate = s.startDate;
    if (s.startDate === null && s.endDate !== null) s.startDate = s.endDate;
    if (s.startDate > s.endDate) s.endDate = s.startDate;
  };

  print = (key, type) => {
    if (type === 'Cervico') {
      const url = `/api/rq/cervico/report/pdf?type=def&idx=${key}`;
      this.root.pdfWindow.print(url);
    } else {
      const url = `/api/rq/other-test/${type}/${key}/report`;
      this.root.pdfWindow.print(url);
    }
  };
}
