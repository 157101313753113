import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Alert, Button, CustomInput } from 'reactstrap';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { AI_Alert, aiAlertMessage2 } from 'libs/Utils';
import { toggleFullscreen } from 'libs/Screen';
import { resultGraphic } from 'libs/Utils';
import { FaTimesCircle, FaArrowLeft, FaQuestionCircle } from 'react-icons/fa';
import DrawRoiPanel from './DrawRoiPanel';
import DrawRoiNaviBar from './DrawRoiNaviBar';

import './CerviAiResultWindowWithoutPatInfo.scss';

@inject('auth')
@inject('locale')
@inject('screen')
@inject('cerviAi')
@inject('cerviAiResultWindowWithoutPatInfo')
@withRouter
@observer
class CerviAiResultWindowWithoutPatInfo extends Component {
  state = {
    value: false
  };

  el = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  handlePictureContextMenu = (e) => {
    e.preventDefault();
    this.props.cerviAiResultWindowWithoutPatInfo.init();
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.cerviAiResultWindowWithoutPatInfo.init();
    }
  }

  handleCheckBoxClick = (idx) => {
    this.props.cerviAiResultWindowWithoutPatInfo.toggleRoiCheck(idx);
  };

  handlePictureClick = (pIdx, e) => {
    e.stopPropagation();
    this.props.cerviAiResultWindowWithoutPatInfo.setCurrentPic(pIdx);
  };

  handleRoiNameSaveClick = (roiIdx) => {
    console.log('handleRoiNameSaveClick', roiIdx);
    // this.props.cerviAiResultWindow.saveRoiName(roiIdx);
  };

  handleRoiName = (e, idx) => {
    console.log('handleRoiName', idx, e.target.value);
    // this.props.cerviAi.changeRoiName(idx, e.target.value);
  };

  render() {
    const { auth, locale, cerviAiResultWindowWithoutPatInfo } = this.props;

    if (cerviAiResultWindowWithoutPatInfo.show) {
      const { result, currentPic, roiList } = cerviAiResultWindowWithoutPatInfo;
      const { rqDspResult, aiIdx, classResult, probability } = result[currentPic];

      const url = `/api/rq/cerviai/${aiIdx}/image`;

      return (
        <div className="CerviAiResultWindowWithoutPatInfo layer-window" ref={this.el}>
          <div className="area-back-button">
            <Button size="sm" onClick={cerviAiResultWindowWithoutPatInfo.init}>
              <FaArrowLeft />
            </Button>
          </div>
          <div className="picture-container">
            <div className="area-picture" onContextMenu={this.handlePictureContextMenu}>
              <DrawRoiPanel url={url} roiList={roiList} classResult={classResult} />
            </div>
            <ul className="area-picture-list">
              {result.map(
                (g, j) => (
                  g.roiList.map((roi) => {
                    return (roi.checked = true);
                  }),
                    (
                      <div className={cx('picture', { active: currentPic === j })} key={g.idx} onClick={(e) => this.handlePictureClick(j, e)}>
                        <DrawRoiPanel url={`/api/rq/cerviai/${g.aiIdx}/image`} roiList={g.roiList} classResult={g.classResult} active={currentPic === j} thumb />
                      </div>
                    )
                )
              )}
            </ul>
          </div>
          {auth.doctor.useDrawTool && (
            <DrawRoiNaviBar currentPic={currentPic} handlePictureClick={this.handlePictureClick} handleFullscreen={this.handleFullscreen} expand={this.state.expand} />
          )}
          <div className="area-info h-flex">
            <div className="button-box justify-content-end">
              <span className="d-none d-md-block">
                <FaQuestionCircle /> <FormattedMessage id="comp.dialog.closeInfo" />
              </span>
              <Button size="sm" onClick={cerviAiResultWindowWithoutPatInfo.init}>
                <FaTimesCircle />
              </Button>
            </div>

            <div className="descr-container">
              {Object.keys(AI_Alert).map((key) => {
                const alert = AI_Alert[key];
                const showAlert = rqDspResult === key;

                return (
                  showAlert && (
                    <Fragment key={key}>
                      <Alert color="secondary" className="mt-5 pt-4">
                        <div className="airesultBox">
                          <h3 className="rounded-lg">{locale.fm('field.AIResult')}</h3>
                        </div>
                        {alert.message1}
                      </Alert>

                      <Alert color="success">
                        <p>
                          <FormattedMessage id="field.ai.alert" />
                        </p>
                        <p>
                          <FormattedMessage id="field.ai.alert2" />
                        </p>
                      </Alert>

                      {rqDspResult !== 'Inadequate' && (
                        <Alert color="secondary" className="mt-5 pt-4">
                          <h4 style={{ textDecorationLine: 'underline' }}>
                            <strong>{locale.fm('field.ref')}</strong>
                          </h4>
                          <hr />
                          {resultGraphic(rqDspResult, classResult)}
                          {aiAlertMessage2(classResult, probability)}
                          {alert.message2}
                          {locale.fm('field.ai.confidenceScore')}
                        </Alert>
                      )}
                    </Fragment>
                  )
                );
              })}
              {auth.doctor.useDrawTool && (
                <Alert color="info">
                  <h4 style={{ textDecorationLine: 'underline' }}>
                    <strong>{locale.fm('field.ai.roi')}</strong>
                  </h4>
                  <hr />
                  {roiList.map((roi, i) => {
                    if (roi.coordinate === '0,0,0,0') {
                      return null;
                    }
                    return (
                      <div key={roi.idx} className="d-flex align-items-center">
                        <div>
                          <CustomInput type="checkbox" id={`checkbox-${roi.idx}`} checked={roi.checked} onChange={() => this.handleCheckBoxClick(roi.idx)} />
                        </div>
                        <div>
                          <CustomInput type="text" placeholder="Label Name" id={`roi-name-${roi.idx}`} value={roi.roiName} onChange={(e) => this.handleRoiName(e, roi.idx)} />{' '}
                        </div>
                        <Button size="sm" color="primary" className="roi-button ml-1" onClick={() => this.handleRoiNameSaveClick(roi.idx)}>
                          <FormattedMessage id="button.save" />
                        </Button>
                      </div>
                    );
                  })}
                </Alert>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default CerviAiResultWindowWithoutPatInfo;