import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Form,
  Label,
  ButtonGroup,
  Row,
  Col,
  Input,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  CardFooter
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { FaFileUpload, FaSearch, FaTimes, FaTrash, FaInfoCircle, FaBox } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { DataGrid, DateTimePicker } from 'components/common';

import { CardMask, PictureBox } from 'components/common';
import moment from 'moment';
import { CerviAiResultGridCard, CerviAiResultTable, CerviAiDeleteWindow, DrawRoiPanel } from '.';
import cx from 'classnames';
import './CerviAi.scss';
import CerviAiRequestWindow from './CerviAiRequestWindow';
import RequestConfirm from 'components/cervico/request/RequestConfirm';
import { CerviBoxAddForAIAnalysisWindow } from 'components/cervibox';
import { toast } from 'react-toastify';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { csCellRenderer } from 'libs/Utils';

const fIELD_INIT = {
  Normal: 'N',
  Atypical: 'A',
  LowGrade: 'P1',
  HighGrade: 'P2'
};
const CONVERT_TO_CLASSRESULT_MAP = {
  N: 'Normal',
  A: 'Atypical',
  P1: 'Abnormal(Low grade lesion)',
  P2: 'Abnormal(High grade lesion)'
};

@inject('cerviAi')
@inject('cerviAiResultWindow')
@observer
class CerviAi extends Component {
  cerviAiGridApi = null;

  handleResultGridReady = (params) => {
    this.cerviAiGridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    this.cerviAiGridApi.ensureIndexVisible(0, 'top');
  };

  componentWillUnmount() {
    this.props.cerviAi.init();
  }
  cerviAiCsGuideGridApi = null;

  handleCsGuideGridReady = (params) => {
    this.cerviAiCsGuideGridApi = params.api;
  };

  render() {
    const {
      cerviAi,
      cerviAi: { reqDialog }
    } = this.props;

    return (
      <div className="CerviAi">
        <div className="container-fluid h-flex">
          <SearchFormCard />
          <Row className="flex-grow-1">
            <Col md="6" className="left-area">
              <CerviAiResultGridCard onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} />
            </Col>
            <Col md="6">
              <ResultPreviewCard />
            </Col>
          </Row>
          <CardMask show={cerviAi.mask} />
          <CerviAiRequestWindow store={reqDialog} />
          <CerviBoxAddForAIAnalysisWindow />
          <CerviAiCsGuideWindow onGridReady={this.handleCsGuideGridReady} />
        </div>
      </div>
    );
  }
}

@inject('auth')
@inject('locale')
@inject('cerviAi')
@inject('cerviAiDeleteWindow')
@inject('cerviBoxAddForAIAnalysis')
@observer
class ResultPreviewCard extends Component {
  state = {
    reqFromAi: false
  };

  handleDeleteClick = () => {
    const { preview } = this.props.cerviAi;
    if (preview.aiResultList.length === 0) {
      toast.warn(this.props.locale.fm('field.ai.noresult'));
      return;
    }

    const idxs = preview.aiResultList
      .filter((img) => img.checked)
      .map((el) => {
        return el.idx;
      });
    const wIdxs = this.props.cerviAi.selectedWorklistIdxList();
    if (idxs.length === 0) {
      toast.warn(this.props.locale.fm('store.cerviai.nosel'));
      return;
    } else if (wIdxs.length > 0) {
      toast.warn(this.props.locale.fm('store.cerviai.requestPic'));
      return;
    } else if (preview.aiResultList.length === idxs.length) {
      toast.warn(this.props.locale.fm('store.cerviai.least1'));
      return;
    } else {
      this.props.cerviAiDeleteWindow.setOpen(true);
    }
    // this.props.cerviAi.deleteImg();
  };
  handleRequestClick = () => {
    const { preview } = this.props.cerviAi;
    if (preview.aiResultList.length === 0) {
      toast.warn(this.props.locale.fm('field.ai.noresult.request'));
      return;
    }

    this.setState({
      reqFromAi: true
    });
    this.props.cerviAi.selectRequest();
  };

  handleCerviboxAddClick = () => {
    const { preview } = this.props.cerviAi;
    this.props.cerviBoxAddForAIAnalysis.show(preview);
  };

  render() {
    const { auth } = this.props;
    const { preview, currentPic, reqDialog } = this.props.cerviAi;
    const hasResult = preview ? preview.aiResultList.length > 0 : false;

    return (
      <div className="ResultPreviewCard h-flex">
        <Card className="flex-grow-1">
          {preview.idx && (
            <>
              <CardHeader className="card-toolbar d-flex flex-wrap align-items-center">
                {auth.doctor.useCerviboxYN && (
                  <Button size="sm" color="purple" onClick={this.handleCerviboxAddClick} className="ml-auto">
                    <FaBox /> <FormattedMessage id="button.cervibox" />
                  </Button>
                )}
                {auth.doctor.showResult && auth.doctor.useRequest && (
                  <Button size="sm" color="primary" onClick={this.handleRequestClick} className={auth.doctor.useCerviboxYN ? '' : 'ml-auto'}>
                    <FaFileUpload /> <FormattedMessage id="button.request" />
                  </Button>
                )}
                <Button size="sm" color="danger" onClick={() => this.handleDeleteClick()} className={auth.doctor.showResult && auth.doctor.useRequest ? '' : 'ml-auto'}>
                  <FaTrash /> <FormattedMessage id="button.remove" />
                </Button>
              </CardHeader>
              <CardBody className="result-preview-body-area">
                <div className="ofy-auto h-flex">
                  {hasResult > 0 ? (
                    <div className="picture">
                      <DrawRoiPanel
                        url={`/api/rq/cerviai/${preview.aiResultList[currentPic].idx}/image`}
                        roiList={preview.aiResultList[currentPic].roiList}
                        classResult={preview.aiResultList[currentPic].classResult}
                        forPreview
                      />
                    </div>
                  ) : (
                    <div className="picture">
                      <PictureBox contain src={`/api/rq/cervico/picture/${preview.pictureIdxs[currentPic]}`} />
                    </div>
                  )}
                  <PictureListCard />
                  <CerviAiResultTable result={preview} currentPic={currentPic} />
                  <CerviAiRequestWindow store={reqDialog} />
                  <CerviAiDeleteWindow />
                  <RequestConfirm reqFromAi={this.state.reqFromAi} />
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </div>
    );
  }
}

@inject('locale')
@inject('cerviAi')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.cerviAi.changeSearch(i.name, i.value);
  };

  handleLast1wClick = () => {
    this.props.cerviAi.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.cerviAi.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1mClick = () => {
    this.props.cerviAi.changeSearch('startDate', moment().startOf('day').add(-1, 'month').toDate());
    this.props.cerviAi.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast3mClick = () => {
    this.props.cerviAi.changeSearch('startDate', moment().startOf('day').add(-3, 'month').toDate());
    this.props.cerviAi.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleSearchClick = async () => {
    await this.props.cerviAi.triggerSearch();
  };

  handleResetClick = () => {
    this.props.cerviAi.triggerReset();
  };

  handleTESTClick = () => {
    this.props.cerviAi.getScGuide();
  };

  render() {
    const { search: s } = this.props.cerviAi;
    const hc = this.handleSearchChange;

    return (
      <Form className="search">
        <Card className="searchFormCard">
          <CardHeader>
            <FormattedMessage id="comp.cerviai.title.searchcondition" />
          </CardHeader>
          <CardBody>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.analydate" />
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1mClick} outline>
                    <FormattedMessage id="button.last1months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3mClick} outline>
                    <FormattedMessage id="button.last3months" />
                  </Button>
                </ButtonGroup>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="sm" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="sm" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
            </div>
            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      </Form>
    );
  }
}
@inject('locale')
@inject('zoomWindow')
@inject('cerviAi')
@inject('auth')
@observer
class PictureListCard extends Component {
  handlePictureClick = (pIdx, e) => {
    e.stopPropagation();
    this.props.cerviAi.setCurrentPic(pIdx);
  };
  handlePictureCheckChange = (pIdx, e) => {
    const { cerviAi } = this.props;
    cerviAi.selectImg(pIdx, e.target.checked);
  };

  render() {
    const { aiResultList, idx, pictureIdxs } = this.props.cerviAi.preview;
    return (
      <Card className="picture-list-card">
        <CardBody className="np h-flex">
          <div className="CerviAiPictureList">
            <ul className="area-picture-list" style={{ display: 'flex' }}>
              {aiResultList.length > 0
                ? aiResultList.map((g, j) => (
                  <li key={g.idx}>
                    <ul>
                      <Picture gIdx={idx} cIdx={j} picture={g} onPictureClick={this.handlePictureClick} onPictureCheckChange={this.handlePictureCheckChange} />
                    </ul>
                  </li>
                ))
                : pictureIdxs.map((g, j) => (
                  <li key={g}>
                    <ul>
                      <Picture cIdx={j} picture={g} onPictureClick={this.handlePictureClick} />
                    </ul>
                  </li>
                ))}
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  }
}

@inject('locale')
@inject('cerviAi')
@inject('cerviAiCsGuide')
@observer
class Picture extends Component {
  handleProbClick = (classResult, prob) => {
    this.props.cerviAiCsGuide.openScGuide(classResult, prob);
  };

  render() {
    const { locale, cIdx, picture: p, onPictureClick, onPictureCheckChange } = this.props;
    const hasAIResult = this.props.cerviAi.preview.aiResultList.length > 0;

    return (
      <li key={cIdx} className={cx({ checked: p.checked })}>
        {hasAIResult ? (
          <Card>
            <div className="reg-date pt-1 pb-1">
              <CustomInput
                type="checkbox"
                id={`p-${p.idx}`}
                label={p.takeDate ? moment(p.takeDate).format('YYYY-MM-DD HH:mm:ss') : ''}
                onChange={(e) => onPictureCheckChange(cIdx, e)}
                checked={p.checked}
              />
            </div>
            <div className="card-img" onClick={(e) => onPictureClick(cIdx, e)}>
              <DrawRoiPanel url={`/api/rq/cerviai/${p.idx}/image`} roiList={p.roiList} classResult={p.classResult} forPreview thumb />
            </div>
            <div className="p-1 ml-1 d-flex justify-content-between">
              {p.classResult ? (
                p.classResult === 'N' ? (
                  <p className="m-0 text-primary">{p.rqDspResult}</p>
                ) : p.classResult === 'A' ? (
                  <p className="m-0 text-success">Normal</p>
                ) : p.classResult === 'P1' ? (
                  <p className="m-0 text-coral">{p.rqDspResult}</p>
                ) : p.classResult === 'P2' ? (
                  <p className="m-0 text-crimsonRed">{p.rqDspResult}</p>
                ) : p.rqDspResult === 'Inadequate' ? (
                  <p className="m-0 text-secondary">{p.rqDspResult}</p>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {/* <span style={{ cursor: 'pointer' }} onClick={() => this.handleProbClick(p.classResult, p.probability)}>
                {p.probability} <FaInfoCircle /> */}
              <span>{p.probability}</span>
            </div>
          </Card>
        ) : null}
      </li>
    );
  }
}

@inject('locale')
@inject('cerviAi')
@inject('cerviAiCsGuide')
@observer
class CerviAiCsGuideWindow extends Component {
  state = {
    viewPct: true
  };

  handleClose = () => {
    this.props.cerviAiCsGuide.closeCsGuide();
  };

  handleViewPct = () => {
    this.setState({ viewPct: !this.state.viewPct }, () => {
      this.gridApi.refreshCells({ force: true });
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;

    const { prob, ai } = this.props.cerviAiCsGuide.selectData;
    const data = this.props.cerviAiCsGuide.csGuideData;
    const rowIndex = data.findIndex((r) => r.cs === Math.floor(prob / 10) * 10 && r.ai === ai);

    // 랜더링 시 ai 결과에 맞춰 스크롤 위치 조정
    if (ai === '' || ai === 'TD') return;
    this.gridApi.ensureIndexVisible(rowIndex, 'middle');
  };

  convertToClassResult = (r) => {
    return CONVERT_TO_CLASSRESULT_MAP[r] || '';
  };

  correctPercent = (ai, p) => {
    const data = this.props.cerviAiCsGuide.csGuideData;
    if (ai === '' || ai === 'TD') return;

    const row = data.find((r) => r.cs === Math.floor(p / 10) * 10);
    if (ai === 'N' || ai === 'A') {
      return (
        <p className="mb-0">
          <FormattedMessage id="field.ai.csguide.guideNA" values={{ ai: this.convertToClassResult(ai), npct: row.npct + '%', apct: row.apct + '%' }} />
        </p>
      );
    }
    if (ai === 'P1' || ai === 'P2') {
      return (
        <p className="mb-0">
          <FormattedMessage id="field.ai.csguide.guideP1P2" values={{ ai: this.convertToClassResult(ai), p1pct: row.p1pct + '%', p2pct: row.p2pct + '%' }} />
        </p>
      );
    }

    return '';
  };

  render() {
    const { locale } = this.props;
    const { ai, prob } = this.props.cerviAiCsGuide.selectData;
    const data = this.props.cerviAiCsGuide.csGuideData;

    const defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true
    };

    const getCountOrPctField = (d) => {
      const field = d.colDef.headerName;
      const row = d.data;

      if (this.state.viewPct) {
        if (field === 'Normal') {
          return row['npct'] + '%';
        } else if (field === 'Atypical') {
          return row['apct'] + '%';
        } else if (field === 'LowGrade') {
          return row['p1pct'] + '%';
        } else if (field === 'HighGrade') {
          return row['p2pct'] + '%';
        } else return '';
      } else {
        return row[field];
      }
    };
    const getAIResultField = (d) => {
      const row = d.data;

      if (row.ai === '' || row.ai === 'TD') return '';
      if (row.ai === 'N') return locale.fm('field.ai.n');
      if (row.ai === 'A') return locale.fm('field.ai.a');
      if (row.ai === 'P1') return locale.fm('field.ai.p1');
      if (row.ai === 'P2') return locale.fm('field.ai.p2');
    };

    const getCellStyle = (params) => {
      const field = params.colDef.headerName;
      const row = params.data;

      // 특정 조건에 따라 셀 배경색을 동적으로 설정
      // 선택한 사진의 AI 결과와 prob 값이 일치하는 셀
      if (fIELD_INIT[field] === ai && row.cs === Math.floor(prob / 10) * 10 && row.ai === ai) {
        return { backgroundColor: '#c6efce' };
        //row
      } else if (row.cs === Math.floor(prob / 10) * 10 && row.ai === ai) {
        return { backgroundColor: '#ffeb9c' };
        //column
      } else if (field === 'Normal' || field === 'Atypical') {
        return { backgroundColor: 'rgb(27, 142, 183, 0.2)' };
      } else if (field === 'LowGrade' || field === 'HighGrade') {
        return { backgroundColor: 'rgb(246, 72, 70, 0.2)' };
      } else {
        return null; // 기본 스타일 유지
      }
    };

    const columnDefs = [
      {
        headerName: 'Prediction',
        children: [
          { headerName: 'CS', field: 'cs', width: 75, cellStyle: getCellStyle, cellRenderer: csCellRenderer },
          { headerName: 'AI', valueGetter: getAIResultField, width: 110, cellStyle: getCellStyle },
          { headerName: 'Count', field: 'cnt', width: 65, cellStyle: getCellStyle }
        ]
      },
      {
        headerName: 'Normal',
        children: [
          { headerName: 'Normal', valueGetter: getCountOrPctField, width: 80, cellStyle: getCellStyle },
          { headerName: 'Atypical', valueGetter: getCountOrPctField, width: 80, cellStyle: getCellStyle }
        ]
      },
      {
        headerName: 'Abnormal',
        children: [
          { headerName: 'LowGrade', valueGetter: getCountOrPctField, width: 80, cellStyle: getCellStyle },
          { headerName: 'HighGrade', valueGetter: getCountOrPctField, width: 80, cellStyle: getCellStyle }
        ]
      }
    ];
    return (
      <Modal isOpen={this.props.cerviAiCsGuide.viewCsGuide} toggle={this.handleClose} zIndex="940" className="csguide-modal" size="lg" style={{ width: '600px' }}>
        <ModalHeader toggle={this.handleClose}>{locale.fm('comp.cerviai.title.csguide')}</ModalHeader>
        <ModalBody className="h-flex">
          {ai === 'TD' || ai === '' ? (
            ''
          ) : (
            <Alert>
              <FormattedMessage
                id="field.ai.csguide.result"
                values={{ ai: this.convertToClassResult(ai), cs: `${Math.floor(prob / 10) * 10} ~ ${Math.floor(prob / 10) * 10 + 10}` }}
              />
              <br />
              <FormattedMessage id="field.ai.csguide.result2" />
              <br />
              <br />
              {this.correctPercent(ai, prob)}
              <br />
              <FormattedMessage id="field.ai.csguide.end" values={{ ai: this.convertToClassResult(ai) }} />
              <br />
              <br />
              <FormattedMessage id="field.ai.csguide.version" />
            </Alert>
          )}
          <div className="ml-auto">
            <Input type="checkbox" checked={this.state.viewPct} onChange={this.handleViewPct} />
            <FormattedMessage id="field.ai.csguide.viewPct" />
          </div>
          <div className="ag-theme-balham" style={{ height: '360px' }}>
            <AgGridReact columnDefs={columnDefs} defaultColDef={defaultColDef} rowData={data} onGridReady={this.onGridReady} />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default CerviAi;