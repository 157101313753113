import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Button, FormGroup, Form, Label, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FaSearch, FaRedoAlt, FaArrowLeft } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';

import moment from 'moment';

import DrawRoiPanel from 'components/cerviai/DrawRoiPanel';
import { androidDspResultBadge } from 'libs/Utils';

import './AiListCardForAndroid.scss';

@inject('android')
@observer
class AiListCardForAndroid extends Component {
  componentDidMount() {
    this.props.android.loadDataGrid();
  }

  componentWillUnmount() {
    this.props.android.initSearch();
  }

  render() {
    return (
      <>
        <Card className="AiListCardForAndroid basis-300">
          <CardHeader className="card-toolbar d-flex flex-wrap align-items-center">
            <NavLink to="/android" className="back-btn">
              <Button size="lg" color="secondary" className="mr-auto">
                <FaArrowLeft />
              </Button>
            </NavLink>
          </CardHeader>
          <CardHeader>
            <SearchFormCard />
          </CardHeader>
          <CardBody className="np h-flex">
            <PictureList />
          </CardBody>
          <CardFooter style={{ fontWeight: 'bold' }}>
            <CheckedCount className="mr-auto" />
          </CardFooter>
        </Card>
      </>
    );
  }
}

@inject('android')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.android.changeSearch(i.name, i.value);
  };

  handleSearchClick = async () => {
    await this.props.android.triggerSearch();
  };

  handleResetClick = () => {
    this.props.android.triggerReset();
  };

  render() {
    const { search: s } = this.props.android;
    const hc = this.handleSearchChange;

    return (
      <Form className="search">
        <Card className="searchFormCard">
          <CardHeader>
            <FormattedMessage id="comp.android.search.title" />
          </CardHeader>
          <CardBody>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="lg" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="lg" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Button size="lg" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch />
                </Button>
                <span>&nbsp;</span>
                <Button size="lg" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaRedoAlt />
                </Button>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      </Form>
    );
  }
}

@inject('android')
@observer
class CheckedCount extends Component {
  render() {
    const dataGrid = this.props.android.dataGrid;
    return (
      <div>
        <FormattedMessage id="field.total" /> : {dataGrid.total}
      </div>
    );
  }
}

@inject('android')
@observer
class PictureList extends Component {
  render() {
    const { rows } = this.props.android.dataGrid;

    return (
      <div className="picture-list">
        <ul>
          {rows.map((g) => (
            <PictureGroup key={g.idx} pictureGroup={g} />
          ))}
        </ul>
      </div>
    );
  }
}

@inject('locale')
class PictureGroup extends Component {
  render() {
    const { pictureGroup: g, locale } = this.props;

    let patTitle = `${locale.fm('field.patient')} : ${g.patName}`;
    if (g.age !== '') patTitle += `(${g.age})`;
    if (g.menopause) patTitle += ` | ${locale.fm('field.menopause')}`;
    if (g.isPreg) patTitle += ` | ${locale.fm('field.pregnant')}`;
    if (g.postPartum) patTitle += ` | ${locale.fm('field.postPartum')}`;

    return (
      <li>
        <div className="send-title">
          <span>
            {androidDspResultBadge(g.classResult)} {patTitle}
          </span>
          <span>
            {g.chartNum !== '' && `${locale.fm('field.chartnum')} : ${g.chartNum} | `}
            {moment(g.regDate).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>
        <ul>
          {g.aiResultList.map((p, j) => (
            <Picture key={p.idx} pictureGroup={g} picture={p} currentPic={j} />
          ))}
        </ul>
      </li>
    );
  }
}

@inject('android')
@inject('cerviAiResultWindow')
@observer
class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  setLoading = (loading) => {
    this.setState({ loading });
  };

  handlePopover = (group, p, currentPic) => {
    this.props.cerviAiResultWindow.setList(group);
    this.props.cerviAiResultWindow.setCurrentPic(currentPic);
  };

  render() {
    const { pictureGroup: g, picture: p, currentPic } = this.props;
    return (
      <li key={p.fCode}>
        <div className={`picture ${!this.state.loading && p.classResult}`} onClick={() => this.handlePopover(g, p, currentPic)}>
          <DrawRoiPanel
            url={`/api/rq/cerviai/${p.idx}/image`}
            roiList={p.roiList}
            classResult={p.classResult}
            probability={p.probability}
            forPreview
            thumb
            setLoadingFromAndroid={this.setLoading}
          />
        </div>
      </li>
    );
  }
}

export default AiListCardForAndroid;
