import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, CustomInput } from 'reactstrap';
import Logo from 'resources/ntl.svg';
import hcLogo from 'resources/hcLogo.svg';
import DoctorEditWindow from './DoctorEditWindow';
import MedicalDeviceWindow from './MedicalDeviceWindow';

import { toggleFullscreen } from 'libs/Screen';
import { isWindows } from 'libs/Utils';

import { FormattedMessage } from 'react-intl';
import { FaExpand, FaCompress, FaUserMd, FaUserEdit, FaHospital, FaSignOutAlt, FaCloudDownloadAlt } from 'react-icons/fa';
import { IoIosMenu } from 'react-icons/io';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import './Header.scss';
import debounce from 'lodash.debounce';

import axios from 'axios';
import yaml from 'js-yaml';

@inject('auth')
@inject('locale')
@inject('screen')
@inject('pictureList')
@inject('doctorEditWindow')
@withRouter
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  downloadUrl = '';

  handleFullscreen = () => {
    toggleFullscreen();
  };

  handleZoomIn = () => {
    window.rq.zoomIn();
  };

  handleZoomOut = () => {
    window.rq.zoomOut();
  };

  handleDownload = () => {
    if (this.downloadUrl) {
      window.location.href = this.downloadUrl;
    }
    //window.location.href =
  };

  handleLogout = async () => {
    const { history, auth } = this.props;
    await auth.logout();
    history.replace('/');
  };

  handleEditDoctor = () => {
    this.props.doctorEditWindow.setDoctorEdit();
  };

  handleChangeReqConfirmYN = (e) => {
    this.checkedConfirmYN(e.target.checked);
  };

  handleMedicalDevice = () => {
    this.setState({
      isMedicalDeviceOpen: !this.state.isMedicalDeviceOpen
    });
  };

  checkedConfirmYN = debounce((bool) => {
    this.props.auth.reqConfirmYNChange(bool);
  }, 200);

  componentDidMount() {
    axios
      .get(`/release/latest.yml?timestamp=${new Date().getTime()}`)
      .then((response) => {
        const info = yaml.load(response.data, 'UTF-8');
        this.downloadUrl = `/release/${info.path}`;
      })
      .catch((err) => {
        console.log(err);
      });

    this.props.doctorEditWindow.changeRequired(this.props.auth.doctor);
  }

  render() {
    const { screen, onToggleMenu, auth, locale } = this.props;
    const ReqConfirmYN = auth.doctor.reqConfirmYN;

    return (
      <header className="app-header">
        {this.props.locale._locale === 'ko' ? <img src={Logo} alt="NTL" className="logo" /> : <img src={hcLogo} alt="NTL" className="logo" />}
        <button href="" className="open-button" onClick={onToggleMenu}>
          <IoIosMenu />
        </button>
        <ul className="user-info">
          <li className="d-none d-sm-block">
            <FormattedMessage id="comp.top.unsendPic" /> <span className="badge badge-pill badge-warning">{this.props.pictureList.totalCount}</span>
          </li>
          {/* <li className="d-none d-sm-block">
            SMS전송
            <span className="badge badge-pill badge-warning">15</span>
          </li> */}
          {window.rq ? (
            <ButtonGroup size="sm">
              <Button color="light">
                <FiZoomIn onClick={this.handleZoomIn} />
              </Button>
              <Button color="light">
                <FiZoomOut onClick={this.handleZoomOut} />
              </Button>
            </ButtonGroup>
          ) : (
            ''
          )}
          <li className="d-none d-sm-block">
            <Button size="sm" color="light" onClick={this.handleFullscreen}>
              {screen.fullscreen ? <FaCompress /> : <FaExpand />}
            </Button>
          </li>
          <li>
            <UncontrolledButtonDropdown direction="left">
              <DropdownToggle color="primary" size="sm" caret>
                <FaUserMd /> {auth.doctor.name}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>
                  <FaHospital /> {auth.doctor.hpName}
                </DropdownItem>
                <DropdownItem divider />
                <div className="reqConfirmYN dropdown-item">
                  <CustomInput
                    type="checkbox"
                    id="ReqConfirmYN"
                    label={locale.fm('button.reqConfirmYN')}
                    onChange={(e) => this.handleChangeReqConfirmYN(e)}
                    checked={ReqConfirmYN}
                  />
                </div>
                <DropdownItem divider />
                <DropdownItem onClick={this.handleEditDoctor}>
                  <FaUserEdit /> <FormattedMessage id="button.editDoctor" />
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.handleLogout}>
                  <FaSignOutAlt /> <FormattedMessage id="button.logout" />
                </DropdownItem>
                {!window.rq && isWindows() ? (
                  <>
                    <DropdownItem divider />
                    <DropdownItem onClick={this.handleDownload}>
                      <FaCloudDownloadAlt /> <FormattedMessage id="button.clientDownload" />
                    </DropdownItem>
                  </>
                ) : (
                  ''
                )}
                <DropdownItem divider />
                <DropdownItem onClick={this.handleMedicalDevice}>
                  <FormattedMessage id="button.medicalDevice" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </li>
        </ul>
        <DoctorEditWindow />
        <MedicalDeviceWindow setIsOpen={this.handleMedicalDevice} isOpen={this.state.isMedicalDeviceOpen} />
      </header>
    );
  }
}

export default Header;
