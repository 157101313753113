import React, { useRef, useState, useEffect, Fragment } from 'react';

const NORMAL = 'Normal is Suspected';
const TD = 'Inadequate';
const ABNORMAL = 'Abnormal is Suspected';

const DrawRoiPanel = ({ url, roiList, classResult, active, thumb, forPreview }) => {
  const workspace = useRef(null);
  const svgRoot = useRef(null);
  const [image, setImage] = useState({ width: 0, height: 0, fontSize: 20 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    if (url) {
      setLoading(true);
      img.src = url;
      img.onload = () => {
        setImage({ width: img.width, height: img.height, fontSize: (img.height / 100) * 3 });
        setLoading(false);
      };
    }
    return () => {
      img.onload = null;
    };
  }, [url]);

  const space = image.fontSize / 2;
  const getColor = (morphologyType) =>
    ({
      0: 'blue',
      1: 'yellow',
      2: 'red'
    }[morphologyType]);

  const getLabelText = (morphologyType, coordinate) => {
    if (morphologyType !== 0 || coordinate === '0,0,0,0' || thumb) return '';
    switch (classResult) {
      case 'N':
      case 'A':
        return NORMAL;
      case 'TD':
        return TD;
      default:
        return ABNORMAL;
    }
  };

  const renderRois = (filterFunc, shouldRender) => {
    if (!shouldRender || roiList.length === 0 || classResult === 'TD') {
      return null;
    }

    return roiList.filter(filterFunc).map((roi, i) => {
      const [x, y, width, height] = roi.coordinate.split(',').map(Number);
      return (
        roi.morphologyType === 0 && (forPreview || roi.checked) && (
          <Fragment key={i}>
            <rect x={x} y={y} width={width - x} height={height - y} fill="transparent" stroke={getColor(roi.morphologyType)} strokeWidth="5" />
            <text x={x + space} y={height - space} fill={getColor(roi.morphologyType)} fontSize={image.fontSize} stroke={getColor(roi.morphologyType)} strokeWidth={2}>
              {getLabelText(roi.morphologyType, roi.coordinate)}
            </text>
          </Fragment>
        )
      );
    });
  };

  return (
    <div className="workspace" ref={workspace} style={{ width: '100%', height: '100%' }}>
      <svg className={`svg-root`} ref={svgRoot} style={{ width: '100%', height: '100%' }}>
        <g className="draw-panel-graphic">
          <svg className="svg-content" viewBox={`0 0 ${image.width} ${image.height}`}>
            <g>
              <image href={url} width="100%" height="100%" style={{ pointerEvents: 'none' }} />
            </g>
            {!loading && (
              <g style={{ pointerEvents: 'all' }} stroke="#16C60C" strokeWidth="4" fill="none">
                {renderRois((roi) => roi.morphologyType === 0, ['N'].includes(classResult) || thumb)}
                {renderRois(() => true, !['N', 'TD'].includes(classResult) && !thumb)}
              </g>
            )}
            <g>{active ? <rect x="0" y="0" width="100%" height="100%" stroke="#16C60C" strokeWidth="20" fill="none" /> : null}</g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default DrawRoiPanel;