import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './LanguageModal.scss';

@inject('cervicoRequest')
@inject('android')
@inject('locale')
@observer
class LanguageModal extends Component {
  handleClose = () => {
    this.props.android.init();
  };

  render() {
    const { android, locale } = this.props;

    return (
      <Modal isOpen={android.openLanguage} toggle={this.handleClose} size="xl" centered className="LanguageModal" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <FormattedMessage id="comp.language.title" />
        </ModalHeader>
        <ModalBody>
          <Input type="select" value={locale.value} onChange={(event) => locale.changeLocale(event.target.value)} tabIndex="3">
            <option value="ko">한국어</option>
            <option value="en">English</option>
          </Input>
        </ModalBody>
      </Modal>
    );
  }
}

export default LanguageModal;
