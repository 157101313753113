import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, CustomInput, ButtonGroup } from 'reactstrap';
import { DataGrid, CardMask } from 'components/common';
import { observer, inject } from 'mobx-react';
import OtherTestResultSmsWindow from './OtherTestResultSmsWindow';

import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { FaSearch, FaTimes, FaPrint, FaFileUpload } from 'react-icons/fa';
import { IoIosChatboxes } from 'react-icons/io';
import { MdCompare, MdDesktopMac, MdFlipToBack } from 'react-icons/md';

import { DateTimePicker, CustomPopover } from 'components/common';
import SmsArea from './SmsArea';

import { shortDateCellRenderer, dateCellHmRenderer, socialNumRenderer, smsReqTypeMap } from 'libs/Utils';
import moment from 'moment';

@inject('otherTest')
@inject('otherSmsSend')
@inject('smsTemplate')
@observer
class OtherTest extends Component {
  smsGridApi = null;
  resultGridApi = null;

  handleResultGridReady = (params) => {
    this.resultGridApi = params.api;
  };

  handleSmsGridReady = (params) => {
    this.smsGridApi = params.api;
  };

  handleResetGridScrollbar = () => {
    //스크롤바
    this.resultGridApi.ensureIndexVisible(0, 'top');
  };

  componentDidMount() {
    this.props.otherSmsSend.setShow(false);
    this.props.smsTemplate.getList();
  }

  componentWillUnmount() {
    this.props.otherTest.init();
  }

  render() {
    return (
      <>
        <div className="CardResult container-fluid h-flex">
          <SearchFormCard onAfterSearch={this.handleResetGridScrollbar} />
          <ResultGridCard onGridReady={this.handleResultGridReady} onAfterMove={this.handleResetGridScrollbar} />
          <div className="mb-0" />
          <SmsArea onGridReady={this.handleSmsGridReady} />
          <OtherTestResultSmsWindow />
        </div>
      </>
    );
  }
}

@inject('locale')
@inject('otherTest')
@inject('otherSmsSend')
@inject('otherTestResultSmsWindow')
@observer
class ResultGridCard extends Component {
  gridApi = null;
  selected = [];

  handleGridReady = (params) => {
    this.gridApi = params.api;
    this.props.onGridReady(params);
    this.props.otherTest.loadDataGrid();
  };

  handleGridSelectionChanged = (l) => {
    this.selected = l;
  };

  handleGridRowClick = (data) => {
    this.props.otherTest.print(data.reportId, data.reportKind);
  };

  handleMovePage = async (page) => {
    await this.props.otherTest.loadDataGrid(page);
    this.props.onAfterMove();
  };

  handleResultColumnDef = () => {
    switch (this.props.otherTest.search.reportType) {
      case 'Cervico':
        return 'txtResult';
      case 'LBC':
        return 'memoResult';
      default:
        return 'result';
    }
  };

  handleSmsClick = () => {
    //모두 대상
    const l = this.selected.map((w) => Object.assign({ phone: '' }, w));

    //covid 라면 전화번호 채우기
    if (this.props.otherTest.search.reportType === 'COVID') {
      l.forEach((w) => (w.phone = w.mobile.replace(/-/g, '')));
    }

    if (l.length === 0) {
      toast.warn(this.props.locale.fm('comp.res.vali.show.more1'));
    } else {
      this.props.otherSmsSend.setShow(true);
      this.props.otherSmsSend.addPhoneList(l);
    }
  };

  handleSmsResultClick = (row, smsType) => {
    this.props.otherTestResultSmsWindow.setRequestInfo(row, smsType);
  };

  handleReportBtnClick = (data) => {
    const { reportType } = this.props.otherTest.search;
    const key = reportType === 'Cervico' ? data.idx : data.reportId;

    this.props.otherTest.print(key, reportType);
  };

  render() {
    const { locale, otherTest } = this.props;

    return (
      <Card className="basis-300">
        <CardHeader className="card-toolbar">
          <Button size="sm" onClick={this.handleSmsClick}>
            <IoIosChatboxes /> <FormattedMessage id="button.res.sms" />
          </Button>
        </CardHeader>
        <CardBody className="np h-flex">
          <DataGrid
            columnDefs={[
              { width: 30, checkboxSelection: ({ data }) => (data.reportId && data.completed) || data.statusCode, headerCheckboxSelection: true },
              { headerName: locale.fm('field.fcode'), field: 'idx', width: 110 },
              { headerName: locale.fm('field.receiveddate'), field: 'receivedDate', width: 80, cellRenderer: shortDateCellRenderer, hide: otherTest.search.reportType === 'COVID' },
              { headerName: locale.fm('field.pickdate'), field: 'takeDate', width: 110, cellRenderer: dateCellHmRenderer, hide: otherTest.search.reportType !== 'COVID' },
              { headerName: locale.fm('field.testdate'), field: 'diagnosisDate', width: 110, cellRenderer: dateCellHmRenderer, hide: otherTest.search.reportType !== 'COVID' },
              { headerName: locale.fm('field.patname'), field: 'patName', width: 130 },
              { headerName: locale.fm('field.birthday'), field: 'socialNum', width: 80, cellRenderer: socialNumRenderer },
              { headerName: locale.fm('field.chartnum'), field: 'chartNum', width: 130 },
              { headerName: locale.fm('field.txtresult'), field: this.handleResultColumnDef(), width: 100 },
              {
                headerName: locale.fm('field.resultSum'),
                field: 'resultSum',
                width: 170,
                cellRenderer: ({ value, data }) => (data.result === 'Positive' ? value : ''),
                hide: otherTest.search.reportType !== 'COVID'
              },
              { headerName: locale.fm('button.report'), width: 70, cellRenderer: 'reportClickRenderer', cellClass: 'button' },
              { headerName: locale.fm('field.sms.receiverList.mobile'), field: 'mobile', width: 100, hide: otherTest.search.reportType !== 'COVID' },
              {
                headerName: locale.fm('field.rsltdate'),
                field: 'smsRsltDate',
                valueGetter: ({ data }) => {
                  switch (otherTest.search.reportType) {
                    case 'Cervico':
                      return { data, code: smsReqTypeMap.써비코_검사결과 };
                    case 'LBC':
                      return { data, code: smsReqTypeMap.LBC_검사결과 };
                    case 'STD':
                      return { data, code: smsReqTypeMap.STD_검사결과 };
                    case 'STDRT':
                      return { data, code: smsReqTypeMap.STDRT_검사결과 };
                    case 'HPV':
                      return { data, code: smsReqTypeMap.HPV_검사결과 };
                    case 'COVID':
                      return { data, code: smsReqTypeMap.COVID_검사결과 };
                    default:
                      return { data, code: null };
                  }
                },
                cellRenderer: 'smsClickRenderer',
                width: 140,
                cellClass: 'button'
              },
              {
                headerName: locale.fm('field.retestdate'),
                field: 'smsRetestDate',
                valueGetter: ({ data }) => {
                  switch (otherTest.search.reportType) {
                    case 'Cervico':
                      return { data, code: smsReqTypeMap.써비코_재검진 };
                    case 'LBC':
                      return { data, code: smsReqTypeMap.LBC_재검진 };
                    case 'STD':
                      return { data, code: smsReqTypeMap.STD_재검진 };
                    case 'STDRT':
                      return { data, code: smsReqTypeMap.STDRT_재검진 };
                    case 'HPV':
                      return { data, code: smsReqTypeMap.HPV_재검진 };
                    default:
                      return { data, code: null };
                  }
                },
                cellRenderer: 'smsClickRenderer',
                width: 140,
                cellClass: 'button',
                hide: otherTest.search.reportType === 'COVID'
              }
            ]}
            rowSelection="multiple"
            //모두 선택 가능하게
            //isRowSelectable={({ data }) => data.statusCode > 6}

            onGridReady={this.handleGridReady}
            // onRowDoubleClicked={this.handleGridRowClick}
            onSelectionChanged={this.handleGridSelectionChanged}
            onMovePage={this.handleMovePage}
            dataGrid={this.props.otherTest.dataGrid}
            redraw={this.props.otherTest.t}
            context={{
              componentParent: this
            }}
            frameworkComponents={{
              reportClickRenderer: ReportClickRenderer,
              smsClickRenderer: SmsClickRenderer
            }}
          />
        </CardBody>
        <CardMask show={this.props.otherTest.mask} />
      </Card>
    );
  }
}

@inject('locale')
@inject('auth')
@inject('otherTest')
@inject('otherSmsSend')
@observer
class SearchFormCard extends Component {
  handleSearchChange = (e) => {
    const i = e.target;
    this.props.otherTest.changeSearch(i.name, i.value);

    //검사유형 변경되면 메시지 대상 초기화
    if (i.name === 'reportType') {
      this.props.otherSmsSend.initPhone();
    }
  };

  handleResetClick = async (e) => {
    await this.props.otherTest.triggerReset();
    this.props.onAfterSearch();
  };

  handleSearchClick = async (e) => {
    await this.props.otherTest.triggerSearch();
    this.props.onAfterSearch();
  };

  handleKeyUpEnter = (e) => {
    if (e.key === 'Enter') this.handleSearchClick();
  };

  handleTodayClick = () => {
    this.props.otherTest.changeSearch('startDate', moment().toDate());
    this.props.otherTest.changeSearch('endDate', moment().toDate());
    this.handleSearchClick();
  };

  handleLast3dClick = () => {
    this.props.otherTest.changeSearch('startDate', moment().startOf('day').add(-3, 'day').toDate());
    this.props.otherTest.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1wClick = () => {
    this.props.otherTest.changeSearch('startDate', moment().startOf('day').add(-1, 'week').toDate());
    this.props.otherTest.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast1mClick = () => {
    this.props.otherTest.changeSearch('startDate', moment().startOf('day').add(-1, 'month').toDate());
    this.props.otherTest.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleLast3mClick = () => {
    this.props.otherTest.changeSearch('startDate', moment().startOf('day').add(-3, 'month').toDate());
    this.props.otherTest.changeSearch('endDate', moment().startOf('day').toDate());
    this.handleSearchClick();
  };

  handleRetest = () => {
    this.props.otherTest.changeSearch('startDate', moment().startOf('day').add(-7, 'month').toDate());
    this.props.otherTest.changeSearch('endDate', moment().startOf('day').add(-6, 'month').toDate());
    this.handleSearchClick();
  };

  render() {
    const {
      locale,
      otherTest: { search: s }
    } = this.props;
    const hc = this.handleSearchChange;

    return (
      <Card>
        <CardHeader>{locale.fm('field.other-test.sms')}</CardHeader>
        <CardBody>
          <Form className="search">
            <div className="form-row">
              <FormGroup>
                <Label>{locale.fm('field.other-test.type')}</Label>
                <CustomInput id="cervico" type="radio" name="reportType" value="Cervico" onChange={hc} checked={s.reportType === 'Cervico'} label="Cervico" />
                <CustomInput id="LBC" type="radio" name="reportType" value="LBC" onChange={hc} checked={s.reportType === 'LBC'} label="LBC" />
                <CustomInput id="STD" type="radio" name="reportType" value="STD" onChange={hc} checked={s.reportType === 'STD'} label="STD" />
                <CustomInput id="STDRT" type="radio" name="reportType" value="STDRT" onChange={hc} checked={s.reportType === 'STDRT'} label="STD-RT" />
                <CustomInput id="HPV" type="radio" name="reportType" value="HPV" onChange={hc} checked={s.reportType === 'HPV'} label="HPV" />
                <CustomInput id="COVID" type="radio" name="reportType" value="COVID" onChange={hc} checked={s.reportType === 'COVID'} label="Covid-19" />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.requestdate" />
                </Label>
                <DateTimePicker name="startDate" value={s.startDate} onChange={hc} />
                ~
                <DateTimePicker name="endDate" value={s.endDate} onChange={hc} />
              </FormGroup>
              <FormGroup>
                <ButtonGroup className="date-btns" size="sm">
                  <Button type="button" onClick={this.handleTodayClick} outline>
                    <FormattedMessage id="button.today" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3dClick} outline>
                    <FormattedMessage id="button.last3days" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1wClick} outline>
                    <FormattedMessage id="button.last1weeks" />
                  </Button>
                  <Button type="button" onClick={this.handleLast1mClick} outline>
                    <FormattedMessage id="button.last1months" />
                  </Button>
                  <Button type="button" onClick={this.handleLast3mClick} outline>
                    <FormattedMessage id="button.last3months" />
                  </Button>
                  <Button type="button" onClick={this.handleRetest} id="reTest" outline>
                    <FormattedMessage id="button.reTest" />
                  </Button>
                  <CustomPopover key="reTest" placement="top" target="reTest" title={locale.fm('popover.common.title')} contents={locale.fm('popover.reTest')} />
                </ButtonGroup>
              </FormGroup>
            </div>
            {s.reportType === 'Cervico' ? (
              <div className="form-row">
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.txtresult" />
                  </Label>
                  <Input type="select" bsSize="sm" name="result" value={s.result} onChange={hc}>
                    <option value="">{locale.fm('value.all')}</option>
                    <option value="Negative">{locale.fm('value.result.negative')}</option>
                    <option value="Positive">{locale.fm('value.result.positive')}</option>
                  </Input>
                </FormGroup>
              </div>
            ) : null}

            <div className="form-row">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.patname" />
                </Label>
                <Input className="ime" bsSize="sm" name="patName" value={s.patName} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.chartnum" />
                </Label>
                <Input bsSize="sm" name="chartNum" value={s.chartNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
              </FormGroup>

              {/* {s.reportType !== 'COVID' ? (
                <>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="field.sms.receiverList.mobile" />
                    </Label>
                    <Input bsSize="sm" name="mobile" size="14" value={s.mobile} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="field.txtresult" />
                    </Label>
                    <Input type="select" bsSize="sm" name="result" value={s.result} onChange={hc}>
                      <option value="">{locale.fm('value.all')}</option>
                      <option value="Negative">{locale.fm('value.result.negative')}</option>
                      <option value="Positive">{locale.fm('value.result.positive')}</option>
                    </Input>
                  </FormGroup>
                </>
              ) : (
                <FormGroup>
                  <Label>
                    <FormattedMessage id="field.birthday" />
                  </Label>
                  <Input bsSize="sm" name="socialNum" size="8" value={s.socialNum} onChange={hc} onKeyUp={this.handleKeyUpEnter} />
                </FormGroup>
              )} */}
            </div>

            <div className="form-row flex-row-reverse">
              <FormGroup>
                <Button size="sm" color="success" type="button" style={{ width: '100px' }} onClick={this.handleSearchClick}>
                  <FaSearch /> <FormattedMessage id="button.search" />
                </Button>
                <span>&nbsp;</span>
                <Button size="sm" color="light" type="reset" style={{ width: '100px' }} onClick={this.handleResetClick}>
                  <FaTimes /> <FormattedMessage id="button.reset" />
                </Button>
              </FormGroup>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

const SmsClickRenderer = ({ value: { data, code }, context }) => {
  const invokeParentMethod = () => {
    context.componentParent.handleSmsResultClick(data, code);
  };
  const isValueEmpty = code === null || data[code.field] == null;

  return !isValueEmpty ? (
    // dateCellRenderer로 하면 동일한 날짜가 출력됨, moment로 직접함
    <button onClick={invokeParentMethod} className={`btn ${isValueEmpty ? 'btn-outline-secondary' : 'btn-outline-primary'}`}>
      {moment(data[code.field]).format('YYYY-MM-DD HH:mm')}
    </button>
  ) : (
    <div />
  );
};

class ReportClickRenderer extends Component {
  invokeParentMethod = (d) => {
    //this.props.data 는 render 시에 결정되어 수정된 값 반영 안됨.
    this.props.context.componentParent.handleReportBtnClick(this.props.data);
  };

  render() {
    const { data } = this.props;
    return (data.reportId && data.completed) || data.statusCode > 6 ? (
      <button onClick={this.invokeParentMethod} className="btn btn-outline-primary">
        <FormattedMessage id="button.report" />
      </button>
    ) : (
      ''
    );
  }
}

export default OtherTest;
