import { observable, action, computed, runInAction } from 'mobx';
import axios from 'axios';
import { ajaxErrorHandler } from 'libs/Utils';
import { toast } from 'react-toastify';
import { confirm } from 'libs/Utils';

const key = 'startdate';

//그룹핑 리듀서
function buildGroupReducer(acc, row, i) {
  //row 추가 필드
  const p = Object.assign({ checked: false }, row);
  const g = acc.find((r) => r[key] === p[key]);

  if (g === undefined) {
    acc.push({
      idx: i,
      checked: false,
      [key]: p[key],
      list: [convertModel(p)]
    });
  } else {
    g.list.push(convertModel(p));
  }
  return acc;
}

function convertModel(s) {
  return {
    idx: s.idx,
    startdate: s.startdate,
    takedate: s.takedate,
    url: `/api/rq/cervico/picture/${s.idx}`,
    aiStatus: s.cvgAiRsltStatus,
    aiIdx: s.cvgAiRsltIdx,
    infCount: s.infCount,
    processingYN: s.processingYN,
    classResult: s.classResult,
    rqDspResult: s.rqDspResult,
    probability: s.probability,
    isCervixImage: s.isCervixImage,
    isBlurryImage: s.isBlurryImage,
    isBrightnessOK: s.isBrightnessOK,
    isBloodyImage: s.isBloodyImage,
    isMedicalObjectDetected: s.isMedicalObjectDetected,
    isEligible: s.isEligible,
    isCompleted: s.preprocessingAiRsltIsCompleted,
    aiModelIdx: s.preprocessingAiRsltAiModelIdx,
    imageCrash: s.imageCrash,
    checked: false,
    roiList: s.roiList
  };
}

//정렬 비교자
function sortCompareFunction(p, n) {
  return p[key].localeCompare(n[key]);
}

//채크 사진 합계 리듀서
function checkedCountReducer(acc, row) {
  return acc + row.list.filter((p) => p.checked).length;
}
function countReducer(acc, row) {
  return acc + row.list.length;
}

//채크 사진 idx [] 리듀서
function checkedListReducer(acc, row) {
  return acc.concat(row.list.filter((p) => p.checked).map((p) => p.idx));
}

export default class PictureListStore {
  @observable group = [];
  @observable totalCount = 0;
  @observable mask = false;
  @observable sortDesc = true;
  @observable selectAll = false;
  @observable reFreshAiStatus = false;

  @observable popoverTarget = null;
  @observable popoverPicture = null;
  @observable preprocessingTotal = 0;
  @observable preprocessingCompleted = 0;
  @observable reFreshPreprocessingStatus = false;
  @observable uploadCnt = 0;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this._init();

    //const sse = new EventSource('/api/rq/cervico/picture/monitor');
    //sse.onmessage = m => console.log(m.data);
  }

  //초기화
  @action
  _init = () => {
    this.mask = false;
    this.setListAsGroup([]);
    this.setPopover(null, null);
  };

  //내부용
  @action
  getCount = async () => {
    if (this.root.auth.doctor !== null) {
      try {
        const response = await axios.get('/api/rq/cervico/picture/cnt');
        runInAction('PictureListStore : async getCount -> success', () => {
          this.totalCount = response.data.data;
        });
      } catch (error) {
        ajaxErrorHandler(error);
      }
    }
  };

  @action
  getPreprocessingCount = async () => {
    if (this.root.auth.doctor !== null) {
      try {
        const response = await axios.get('/api/rq/cervico/picture/preprocessing-cnt');
        runInAction('PictureListStore : async getPreprocessingCount -> success', () => {
          const data = response.data.data;
          const total = data.reduce((acc, cur) => acc + cur.totalCnt, 0);
          const completed = data.reduce((acc, cur) => acc + cur.completedCnt, 0);

          this.preprocessingTotal = total;
          this.preprocessingCompleted = completed;

          this.reFreshPreprocessingStatus = !(total === completed);
        });
      } catch (error) {
        ajaxErrorHandler(error);
      }
    }
  };

  @action
  getList = async () => {
    this.mask = true;
    try {
      const response = await axios.get('/api/rq/cervico/picture');
      runInAction('PictureListStore : async getList -> success', () => {
        this.setListAsGroup(response.data);
        this.reFreshAiStatus = !(response.data.find((p) => p.aiStatus === 0 || p.aiStatus === 1) === undefined);
        this.totalCount = this.group.reduce(countReducer, 0);
        this.sortDesc = true;
        this.selectAll = false;
        this.setPopover(null, null);
        this.mask = false;
      });
    } catch (error) {
      runInAction('PictureListStore : async getList -> catch', () => {
        ajaxErrorHandler(error);
        this.setPopover(null, null);
        this.mask = false;
      });
    }
  };

  @action
  setUploadCnt = (cnt) => {
    this.uploadCnt = cnt;
  };

  @action
  setPopover = (target, picture) => {
    this.popoverTarget = target;
    this.popoverPicture = picture;
  };

  @action
  setListAsGroup = (rows) => {
    this.group.replace(rows.reduce(buildGroupReducer, []));
  };

  @action
  selectChange = () => {
    this.selectAll = !this.selectAll;
    this.group.forEach((g) => this.selectGroup(g.idx, this.selectAll));
  };

  @action
  windowClose = () => {
    if (window.Android && window.Android.closeWebView) {
      window.Android.closeWebView();
    }
  };

  @action
  selectGroup = (gIdx, checked) => {
    //transaction(()=>{
    const g = this.getGroup(gIdx);
    g.checked = checked;
    g.list.forEach((p) => (p.checked = checked));
    //});
  };

  @action
  selectPicture = (gIdx, pIdx, checked) => {
    const g = this.getGroup(gIdx);
    g.list.find((p) => p.idx == pIdx).checked = checked;
    g.checked = g.list.every((p) => p.checked == true);
  };

  @computed
  get selectedCount() {
    return this.group.reduce(checkedCountReducer, 0);
  }

  @action
  sortChange = () => {
    this.sortDesc = !this.sortDesc;
    if (this.sortDesc) {
      //desc
      this.group.replace(this.group.slice().sort((p, n) => sortCompareFunction(n, p)));
    } else {
      //asc
      this.group.replace(this.group.slice().sort((p, n) => sortCompareFunction(p, n)));
    }
  };

  /*   @action
    sortAsc = () => {
      this.sord = 'ASC';
      this.group.replace(this.group.slice().sort((p, n) => sortCompareFunction(p, n)));
    };
   */
  getGroup = (gIdx) => {
    return this.group.find((g) => g.idx === gIdx);
  };

  getGroupPicture = (gIdx) => {
    const g = this.group.find((g) => g.idx === gIdx);
    return g ? g.list : [];
  };

  selectedIdxList = () => {
    return this.group.reduce(checkedListReducer, []);
  };

  @action
  getAiStatus = async (gIdx, aiIdx) => {
    try {
      const response = await axios.get(`api/rq/cerviai/status/${aiIdx}`);
      if (response.data.success) {
        runInAction('PictureListStore : async getAiStatus -> success', () => {
          const index = this.getGroup(gIdx).list.findIndex((p) => p.aiIdx === aiIdx);
          let currentStatus = response.data.data.isCompleted;
          if (currentStatus > 1) {
            this.getGroup(gIdx).list[index].aiStatus = currentStatus;
          }
        });
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
  };

  //파일업로드
  @action
  upload = async (files) => {
    if (files.length === 0) return;
    const fa = Array.from(files);

    //fa.forEach(f => console.log(f));

    if (fa.length > 10) {
      toast.warn(this.locale.fm('store.pictureList.vali.cantSendOver10'));
      return;
    }
    if (fa.find((f) => f.name.split('.').pop().toLowerCase() !== 'jpg')) {
      toast.warn(this.locale.fm('store.pictureList.vali.onlyJpg'));
      return;
    }
    if (fa.find((f) => f.size === 0)) {
      toast.error(this.locale.fm('store.pictureList.vali.wrongFile'));
      return;
    }

    const formData = new FormData();
    fa.forEach((f, i) => {
      formData.append('files', f);
    });
    this.root.screen.setProcessLayer(true);

    let toastId = toast(this.locale.fm('store.pictureList.msg.transmiting'), {
      progress: 0
    });
    try {
      const response = await axios.put('/api/rq/cervico/picture/add-ui', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          toast.update(toastId, {
            progress: progress
          });
        }
      });
      toast.dismiss(toastId);
      if (response.data.success) {
        this.uploadCnt = fa.length;

        this.getList();
        this.getPreprocessingCount();
      } else {
        toast.error(this.locale.fm('store.pictureList.msg.transmitingFail'));
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  //파일삭제
  @action
  removeSelected = async () => {
    console.log('???????????');
    //TODO interface로 빼야 함.
    if (this.selectedCount === 0) {
      toast.warn(this.locale.fm('store.pictureList.vali.selectMore1'));
      return;
    }
    if (!(await confirm(this.locale.fm('store.pictureList.pre.remove', { cnt: this.selectedCount })))) return;

    this.root.screen.setProcessLayer(true);
    try {
      const response = await axios.delete('/api/rq/cervico/picture', {
        data: this.selectedIdxList()
      });
      if (response.data.success) {
        toast.success(this.locale.fm('store.pictureList.msg.deleteSuccess'));
        this.getList();
      } else {
        toast.error(this.locale.fm('store.pictureList.msg.deleteFail'));
      }
    } catch (error) {
      ajaxErrorHandler(error);
    }
    this.root.screen.setProcessLayer(false);
  };

  @action
  downloadSelected = () => {
    //TODO progress 를 위해 memory로 다운받거나 cache 이용 고려하라...
    if (this.selectedCount === 0) {
      toast.warn(this.locale.fm('store.pictureList.vali.selectMore1'));
      return;
    }

    if (this.selectedCount > 20) {
      toast.warn(this.locale.fm('store.pictureList.vali.downloadLess20'));
      return;
    }

    document.querySelector('#download-frame').src = `/api/rq/cervico/picture/download?${this.selectedIdxList()
      .map((idx) => `idx=${idx}`)
      .join('&')}`;
  };
}
