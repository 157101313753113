import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PictureListCardForAndroid from './PictureListCardForAndroid';
import CerviAiResultWindowWithoutPatInfoForAndroid from '../../CerviAiResultWindowWithoutPatInfoForAndroid';
import ZoomWindowForAndroid from '../../ZoomWindowForAndroid';
import PatInfoForAndroid from '../../PatInfoForAndroid';
import LanguageModal from '../../LanguageModal';

import './CervicoAndroid.scss';
import { CardMask } from 'components/common';

@inject('pictureList')
@observer
class CervicoAndroid extends Component {
  render() {
    const { pictureList } = this.props;
    return (
      <div className="CervicoAndroid">
        <div className="h-flex">
          <PictureListCardForAndroid />
        </div>
        <CardMask show={pictureList.mask} />
        <CerviAiResultWindowWithoutPatInfoForAndroid />
        <ZoomWindowForAndroid />
        <PatInfoForAndroid />
        <LanguageModal />
      </div>
    );
  }
}

export default CervicoAndroid;
