import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Input, CustomInput, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import './PatInfoForAndroid.scss';

@inject('cervicoRequest')
@inject('android')
@inject('locale')
@observer
class PatInfoForAndroid extends Component {
  handleClose = () => {
    this.props.android.init();
  };

  handleChange = (e) => {
    const i = e.target;
    this.props.android.changePatinfo(i.name, i.value);
  };

  handleNumberChange = (e) => {
    this.props.cervicoRequest.changePatinfo('idxHpAiReqInfo', null);

    const i = e.target;
    this.handleChange({
      target: {
        name: i.name,
        value: `${i.value}`.replace(/[^0-9]+/g, '')
      }
    });
  };

  handleCheckboxChange = (e) => {
    const { checked, name } = e.target;
    this.props.android.changePatinfo(name, checked);
  };

  handleSave = () => {
    this.props.android.save();
  };

  render() {
    const { android, locale } = this.props;
    const { patinfo: p } = android;
    const hc = this.handleChange;
    const hnc = this.handleNumberChange;
    const hcc = this.handleCheckboxChange;

    return (
      <Modal isOpen={android.open} toggle={this.handleClose} size="xl" centered className="PatInfoForAndroid" zIndex="940">
        <ModalHeader toggle={this.handleClose}>
          <FormattedMessage id="comp.req.title.pat" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6" sm="12" className="form-label">
              <Label>
                <FormattedMessage id="field.patname" />
              </Label>
              <Input className="ime" type="text" bsSize="lg" name="patName" size="10" value={p.patName} onChange={hc} />
            </Col>
            <Col md="6" sm="12" className="form-label">
              <Label>
                <FormattedMessage id="field.chartnum" />
              </Label>
              <Input type="text" bsSize="lg" name="chartNum" value={p.chartNum} onChange={hc} />
            </Col>
          </Row>

          <Row>
            <Col md="6" sm="12" className="form-label">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.birthday" />
                </Label>
                <Input
                  bsSize="lg"
                  name="socialNum1"
                  value={p.socialNum1}
                  onChange={hnc}
                  size="6"
                  maxLength="6"
                  onFocus={this.handleFocus}
                  onBlur={() => android.changeSocialNum(1)}
                  placeholder="YYMMDD"
                  style={{ width: '100px' }}
                />
                -
                <Input
                  bsSize="lg"
                  name="socialNum2"
                  value={p.socialNum2}
                  onChange={hnc}
                  size="7"
                  maxLength="1"
                  onFocus={this.handleFocus}
                  onBlur={() => android.changeSocialNum(2)}
                  style={{ width: '35px' }}
                />
              </FormGroup>
            </Col>
            <Col md="6" sm="12" className="form-label">
              <Label>
                <FormattedMessage id="field.age" />
              </Label>
              <Input bsSize="lg" name="age" value={p.age} onChange={hnc} size="4" maxLength="3" style={{ width: '100px' }} />
            </Col>
          </Row>

          <Row className="end">
            <Col md="12" className="form-label">
              <FormGroup>
                <Label>
                  <FormattedMessage id="field.pregnancy" />
                </Label>
                <CustomInput id="request-isPreg-y" type="radio" name="isPreg" value="true" onChange={hc} checked={p.isPreg === 'true'} label={locale.fm('value.yes')} />
                <CustomInput id="request-isPreg-n" type="radio" name="isPreg" value="false" onChange={hc} checked={p.isPreg === 'false'} label={locale.fm('value.no')} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="lg" onClick={this.handleSave}>
            <FormattedMessage id="button.save" />
          </Button>
          <Button color="secondary" size="lg" onClick={this.handleClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PatInfoForAndroid;
