import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Alert, Button, CustomInput } from 'reactstrap';
import { FaTimesCircle, FaArrowLeft, FaQuestionCircle } from 'react-icons/fa';

import { observer, inject } from 'mobx-react';
import cx from 'classnames';

import { toggleFullscreen } from 'libs/Screen';
import { AI_Alert, aiAlertMessage2, resultGraphic } from 'libs/Utils';
import DrawRoiPanel from '../cerviai/DrawRoiPanel';

import './CerviAiResultWindowForAndroid.scss';

@inject('auth')
@inject('locale')
@inject('screen')
@inject('cerviAi')
@inject('cerviAiResultWindow')
@withRouter
@observer
class CerviAiResultWindowForAndroid extends Component {
  state = {
    value: false,
    expand: false,
    image: { url: '', width: '100%', height: '100%', ratio: '', fitRate: '' }
  };

  el = React.createRef();

  handleFullscreen = () => {
    this.setState({ expand: !this.state.expand });
    toggleFullscreen(this.el.current);
  };

  handlePictureContextMenu = (e) => {
    e.preventDefault();
    this.props.cerviAiResultWindow.init();
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.cerviAiResultWindow.init();
    }
  }

  handleCheckBoxClick = (idx) => {
    this.props.cerviAiResultWindow.toggleRoiCheck(idx);
  };

  handlePictureClick = (pIdx, e) => {
    e.stopPropagation();

    if (pIdx < 0 || pIdx >= this.props.cerviAiResultWindow.result.aiResultList.length) return;
    this.props.cerviAiResultWindow.setCurrentPic(pIdx);
  };

  render() {
    const { locale, auth, cerviAiResultWindow } = this.props;
    const { show, result, currentPic, roiList } = cerviAiResultWindow;

    if (!show) return null;

    const { aiResultList, rqDspResult } = result;
    const currentAiResult = aiResultList[currentPic];

    const url = `/api/rq/cerviai/${currentAiResult.idx}/image`;

    return (
      <div className="CerviAiResultWindowForAndroid layer-window" ref={this.el}>
        <div className="area-back-button">
          <Button size="sm" onClick={cerviAiResultWindow.init}>
            <FaArrowLeft />
          </Button>
        </div>
        <div className="picture-container">
          <div className="area-picture" onContextMenu={this.handlePictureContextMenu}>
            <DrawRoiPanel url={url} roiList={roiList} classResult={currentAiResult.classResult} />
          </div>
          <ul className="area-picture-list">
            {aiResultList.map(
              (g, j) => (
                g.roiList.map((roi) => (roi.checked = true)),
                (
                  <div className={cx('picture', { active: currentPic === j })} key={g.idx} onClick={(e) => this.handlePictureClick(j, e)}>
                    <DrawRoiPanel url={`/api/rq/cerviai/${g.idx}/image`} roiList={g.roiList} classResult={g.classResult} active={currentPic === j} thumb />
                  </div>
                )
              )
            )}
          </ul>
        </div>
        <div className="area-info h-flex">
          <div className="descr-container">
            {Object.keys(AI_Alert).map((key) => {
              const alert = AI_Alert[key];
              const showAlert = currentAiResult.classResult === key;

              return (
                showAlert && (
                  <Fragment key={key}>
                    <Alert color="secondary" className="mt-5 pt-4">
                      <div className="airesultBox">
                        <h3 className="rounded-lg">{locale.fm('field.AIResult')}</h3>
                      </div>
                      {resultGraphic(currentAiResult.rqDspResult, currentAiResult.classResult)}
                      {alert.message}
                      <br />
                      <div className="d-flex justify-content-sm-between">
                        <strong>AI Analysis Confidence Score*</strong>
                        <span>{aiResultList[currentPic].probability}</span>
                        <div />
                      </div>
                      <br />
                      {locale.fm('field.ai.confidenceScore')}
                    </Alert>

                    <Alert color="success">
                      <p>
                        <FormattedMessage id="field.ai.alert" />
                      </p>
                      <p>
                        <FormattedMessage id="field.ai.alert2" />
                      </p>
                    </Alert>
                  </Fragment>
                )
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default CerviAiResultWindowForAndroid;
