import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Alert, Button, Col, Container, CustomInput, Row } from 'reactstrap';
import { PictureBox } from 'components/common';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { toggleFullscreen } from 'libs/Screen';
import { AI_Alert, resultGraphic, aiAlertMessage2 } from 'libs/Utils';
import { FaTimesCircle, FaArrowLeft, FaQuestionCircle } from 'react-icons/fa';
import DrawRoiPanel from 'components/cerviai/DrawRoiPanel';
import DrawRoiNaviBar from 'components/cerviai/DrawRoiNaviBar';

import './CerviAiResultWindowWithoutPatInfoForAndroid.scss';

@inject('locale')
@inject('screen')
@inject('cerviAi')
@inject('cerviAiResultWindowWithoutPatInfo')
@withRouter
@observer
class CerviAiResultWindowWithoutPatInfoForAndroid extends Component {
  state = {
    value: false
  };

  el = React.createRef();

  handleFullscreen = () => {
    toggleFullscreen(this.el.current);
  };

  handlePictureContextMenu = (e) => {
    e.preventDefault();
    this.props.cerviAiResultWindowWithoutPatInfo.init();
  };

  componentWillUpdate(prevProps) {
    //router 변경시 닫기
    if (this.props.location !== prevProps.location) {
      this.props.cerviAiResultWindowWithoutPatInfo.init();
    }
  }

  handleCheckBoxClick = (e) => {
    this.setState({ value: !this.state.value });
  };

  handlePictureClick = (pIdx, e) => {
    e.stopPropagation();
    this.props.cerviAiResultWindowWithoutPatInfo.setCurrentPic(pIdx);
  };

  handlePictureClickZoom = (pIdx) => {
    this.props.cerviAiResultWindowWithoutPatInfo.zoomForAndroid(pIdx);
  };

  render() {
    const { locale, cerviAiResultWindowWithoutPatInfo } = this.props;

    if (cerviAiResultWindowWithoutPatInfo.show) {
      const { result, currentPic, roiList } = cerviAiResultWindowWithoutPatInfo;
      const { rqDspResult, aiIdx, classResult, probability } = result[currentPic];

      const url = `/api/rq/cerviai/${aiIdx}/image`;

      return (
        <div className="CerviAiResultWindowWithoutPatInfoForAndroid layer-window" ref={this.el}>
          <div className="area-back-button">
            <Button size="sm" onClick={cerviAiResultWindowWithoutPatInfo.init}>
              <FaArrowLeft />
            </Button>
          </div>
          <div className="picture-container">
            {/* <div className="area-picture" onClick={() => this.handlePictureClickZoom(currentPic)}>
              <PictureBox contain src={`/api/rq/cerviai/${aiIdx}/image`} onContextMenu={this.handlePictureContextMenu} />
            </div> */}
            <div className="area-picture" onContextMenu={this.handlePictureContextMenu}>
              <DrawRoiPanel url={url} roiList={roiList} classResult={classResult} probability={probability} />
            </div>
            <ul className="area-picture-list">
              {result.map(
                (g, j) => (
                  g.roiList.map((roi) => {
                    return (roi.checked = true);
                  }),
                  (
                    <div className={cx('picture', { active: currentPic === j })} key={g.idx} onClick={(e) => this.handlePictureClick(j, e)}>
                      {/* <PictureBox contain src={`/api/rq/cerviai/${g.aiIdx}/image?width=200`} /> */}
                      <DrawRoiPanel
                        url={`/api/rq/cerviai/${g.aiIdx}/image`}
                        roiList={g.roiList}
                        classResult={g.classResult}
                        probability={g.probability}
                        active={currentPic === j}
                        thumb
                      />
                    </div>
                  )
                )
              )}
            </ul>
          </div>
          <div className="area-info h-flex">
            <div className="descr-container">
              {Object.keys(AI_Alert).map((key) => {
                const alert = AI_Alert[key];
                const showAlert = classResult === key;

                return (
                  showAlert && (
                    <Fragment key={key}>
                      <Alert color="secondary" className="mt-5 pt-4">
                        <div className="airesultBox">
                          <h3 className="rounded-lg">{locale.fm('field.AIResult')}</h3>
                        </div>
                        {resultGraphic(rqDspResult, classResult)}
                        {alert.message}
                        <br />
                        <div className="d-flex justify-content-sm-between">
                          <strong>AI Analysis Confidence Score*</strong>
                          <span>{probability}</span>
                          <div />
                        </div>
                        <br />
                        {locale.fm('field.ai.confidenceScore')}
                      </Alert>
                    </Fragment>
                  )
                );
              })}

              <Alert color="success" className="mt-1">
                <p>
                  <FormattedMessage id="field.ai.alert" />
                </p>
                <p>
                  <FormattedMessage id="field.ai.alert2" />
                </p>
              </Alert>
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default CerviAiResultWindowWithoutPatInfoForAndroid;
