import { observable, action, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';

import { searchSerialize, ajaxErrorHandler, calcSocialNum } from 'libs/Utils';

const defaultPatinfo = {
  patName: '',
  chartNum: '',
  socialNum1: '',
  socialNum2: '',
  age: '',
  menopause: false,
  isPreg: 'false',
  postPartum: false,
  delivery: null,
  g: null,
  p: null,
  a: null,
  lastCytology: '',
  lastCytologyDate: '',
  lastHpv: '',
  lastHpvDate: '',
  lastBx: '',
  lastBxDate: '',
  comment: '',
  memo: ''
};

const defaultSearch = {
  android: true,
  startDate: null,
  endDate: null,
  patName: '',
  chartNum: '',
  socialNum: '',
  scope: 'my'
};

const defaultDataGrid = {
  rows: [],
  page: 1,
  totalPage: 1,
  total: 0
};

export default class AndroidStore {
  @observable patinfo = { ...defaultPatinfo };
  @observable search = { ...defaultSearch };
  @observable dataGrid = { ...defaultDataGrid };
  @observable mask = false;
  @observable open = false;
  @observable openLanguage = false;

  constructor(root) {
    this.root = root;
    this.locale = root.locale;
    this.init();
  }

  //초기화
  @action
  init = () => {
    this.patinfo = { ...defaultPatinfo };
    this.initModal();
    this.initLanguageModal();
    this.initSearch();
    this.setMask(false);
  };

  @action
  initModal = () => {
    this.open = false;
  };

  @action
  initLanguageModal = () => {
    this.openLanguage = false;
  };

  @action
  initSearch = () => {
    this.search = Object.assign(
      { ...defaultSearch },
      {
        startDate: moment().startOf('day').add(-7, 'day').toDate(),
        endDate: moment().startOf('day').toDate()
      }
    );
  };

  @action
  setMask = (m) => {
    this.mask = m;
  };

  @action
  setModal = (bool) => {
    this.open = bool;
  };

  @action
  setLanguageModal = (bool) => {
    this.openLanguage = bool;
  };

  @action
  setDataGrid = (data) => {
    this.dataGrid = data;
  };

  @action
  changePatinfo = (key, value) => {
    this.patinfo[key] = value;
  };

  @action
  changeSocialNum = (index) => {
    const p = this.patinfo;
    const ret = calcSocialNum(index, p.socialNum1, p.socialNum2, p.age);
    p.socialNum1 = ret[0];
    p.socialNum2 = ret[1];
    p.age = ret[2];
  };

  @action
  changeSearch = (key, value) => {
    this.search[key] = value;
  };

  // request ai
  save = async () => {
    const { cervicoRequest } = this.root;
    const p = this.patinfo;
    const pList = this.root.pictureList.selectedIdxList();

    const socialNum = cervicoRequest.checkPatInfoThenGetSocialNum(pList, p);
    if (socialNum === false) return;

    try {
      p['socialNum'] = socialNum;
      p['pictureIdxs'] = pList;
      const response = await axios.put('/api/rq/cerviai/add', p, {
        params: {
          checkReqValid: false,
          reqConfirmYN: false
        }
      });
      if (response.data.success) {
        toast.success(this.locale.fm('store.android.save'));
        this.init();
        this.root.pictureList.getList();
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      ajaxErrorHandler(e);
    }
  };

  // ai-results page
  @action
  triggerSearch = async () => {
    await this.loadDataGrid();
  };

  @action
  triggerReset = () => {
    this.initSearch();
    this.loadDataGrid();
  };

  @action
  loadDataGrid = async (page = 1) => {
    this.mask = true;
    try {
      const response = await axios.get('/api/rq/cerviai', {
        params: searchSerialize(this.search, { page: page })
      });
      runInAction('AndroidStore : async loadDataGrid -> success', () => {
        this.setDataGrid(response.data);
        this.mask = false;
      });
    } catch (error) {
      runInAction('AndroidStore : async loadDataGrid -> catch', () => {
        this.mask = false;
        ajaxErrorHandler(error);
      });
    }
  };
}
